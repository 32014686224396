import { Link } from "react-router-dom";
import Cookies from "js-cookie";

const Header = (props) => {
  const logout = () => {
    Cookies.remove("footprint");
    window.location.href = "/";
  };

  const showSidebar = () => {
    const sidebar = document.getElementById("sidebar");
    if (sidebar) {
      sidebar.style.marginLeft = "0px";
    }
  };

  return (
    <header
      id="header"
      className="navbar navbar-expand-lg navbar-fixed navbar-height navbar-container navbar-bordered bg-white"
    >
      <div className="navbar-nav-wrap">
        <a className="navbar-brand" href="/" aria-label="Front">
          <img
            className="navbar-brand-logo"
            src="/assets/img/logo/logo-dark.svg"
            alt="Logo"
          />
        </a>

        <div className="navbar-nav-wrap-content-start">
          <button
            type="button"
            className="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler"
            style={{ opacity: 1 }}
            onClick={showSidebar}
          >
            <i
              className="bi-arrow-bar-right navbar-toggler-short-align"
              data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>'
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title=""
              data-bs-original-title="Collapse"
              aria-label="Collapse"
            ></i>
            <i
              className="bi-arrow-bar-right navbar-toggler-full-align"
              data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>'
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title=""
              data-bs-original-title="Expand"
              aria-label="Expand"
            ></i>
          </button>
        </div>

        <div className="navbar-nav-wrap-content-end">
          <ul className="navbar-nav">
            <li className="nav-item">
              <div className="dropdown">
                <span
                  className="navbar-dropdown-account-wrapper cursor-pointer"
                  id="accountNavbarDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                  data-bs-dropdown-animation=""
                >
                  <div className="avatar avatar-sm avatar-circle">
                    <img
                      className="avatar-img"
                      src="https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
                      alt="Description"
                    />
                    <span className="avatar-status avatar-sm-status avatar-status-success"></span>
                  </div>
                </span>

                <div
                  className="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                  aria-labelledby="accountNavbarDropdown"
                  style={{ width: "16rem" }}
                >
                  <Link
                    to="/settings"
                    className="dropdown-item cursor-pointer"
                    href="#"
                  >
                    Settings
                  </Link>
                  <div className="dropdown-divider"></div>

                  <span
                    className="dropdown-item cursor-pointer"
                    href="#"
                    onClick={logout}
                  >
                    Log out
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
