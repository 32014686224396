import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import Modal from "../components/Modal";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import Requests from "../utils/requests";
const requests = new Requests();

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [activeOrder, setActiveOrder] = useState(null);
  const [activeProduct, setActiveProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [creds, setCreds] = useState({});
  const [search, setSearch] = useState("");

  const [loaders, setLoaders] = useState({
    fullfill: false,
    delete: false,
    expiry: false,
  });
  const [messages, setMessages] = useState({
    fullfill: "",
    delete: "",
    expiry: "",
  });
  const [pager, setPager] = useState({
    total: 0,
    page: 1,
    limit: 10,
    next: null,
    previous: null,
  });

  useEffect(() => {
    readOrders();
    var creds = window.ace.edit("creds");
    creds.setTheme("ace/theme/monokai");
    creds.session.setMode("ace/mode/text");
    setCreds(creds);
  }, []);

  // read dashboard
  const readOrders = async (page = 1, search = null, sortBy = "createdAt") => {
    setIsLoading(true);

    const params = {
      page: page || 1,
      search: search || "",
      sortBy: sortBy || "createdAt",
    };
    await axios
      .post(server + "/v1/order/read", params, config)
      .then((rsp) => {
        setOrders(rsp.data.payload.records);
        setPager(rsp.data.payload.pager);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fullfillOrder = async (e) => {
    e.preventDefault();
    setLoaders({ ...loaders, fullfill: true });

    const params = {
      orderId: activeOrder._id,
      productId: activeProduct._id,
      creds: creds.getValue().split("\n"),
    };

    await axios
      .put(server + "/v1/order/fullfill", params, config)
      .then((rsp) => {
        setLoaders({ ...loaders, fullfill: false });
        setMessages({
          ...messages,
          fullfill: <Alert className="success" message={rsp.data.message} />,
        });
        setTimeout(() => {
          setMessages({ ...messages, fullfill: "" });
        }, 3000);
        readOrders();
      })
      .catch((err) => {
        console.log(err);
      });

    setLoaders({ ...loaders, fullfill: false });
  };

  const deleteOrderProduct = async () => {
    setLoaders({ ...loaders, delete: true });
    const { error, message } = await requests.deleteOrderProduct(
      activeOrder._id,
      activeProduct._id
    );
    setLoaders({ ...loaders, delete: false });
    setMessages({
      ...messages,
      delete: (
        <Alert className={error ? "danger" : "success"} message={message} />
      ),
    });
    readOrders(pager.page, pager.search, pager.sortBy);
  };

  const updateExpiry = async (e) => {
    e.preventDefault();
    setLoaders({ ...loaders, expiry: true });
    const { error, message } = await requests.updateExpiryDate(
      activeOrder._id,
      activeProduct._id,
      e.target.expiry.value
    );
    setLoaders({ ...loaders, expiry: false });
    setMessages({
      ...messages,
      expiry: (
        <Alert className={error ? "danger" : "success"} message={message} />
      ),
    });
    readOrders(pager.page, pager.search, pager.sortBy);
  };

  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <Header title="Orders" />

      {!activeOrder ? (
        <Fragment>
          <div className="card">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center w-100">
                <h5 className="card-title">Orders</h5>
                <div className="btn-group">
                  <select
                    className="form-control me-2"
                    defaultValue="createdAt"
                    onChange={(e) => readOrders(1, null, e.target.value)}
                  >
                    <option value="createdAt">Created At</option>
                    <option value="updatedAt">Updated At</option>
                    <option value="_id">Order ID</option>
                    <option value="price">Price</option>
                  </select>
                  <div class="input-group">
                    <input
                      type="text"
                      className="form-control"
                      style={{
                        maxWidth: "400px",
                      }}
                      placeholder="Search..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span
                      class="input-group-text bg-primary text-white"
                      onClick={() => {
                        readOrders(1, search);
                      }}
                    >
                      <i className="bi-search"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body px-0 pb-0">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Username</th>
                      <th>Email</th>
                      <th>Products</th>
                      <th>Price</th>
                      <th>Payment</th>
                      <th>Payment Gateway</th>
                      <th>Status</th>
                      <th>Created At</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {orders.map((order) => (
                      <tr key={order._id}>
                        <td>{order._id}</td>
                        <td>{order.user?.username || "Guest"}</td>
                        <td>{order.email}</td>
                        <td>{order.products.length}</td>
                        <td>{order.price.toFixed(2)}</td>
                        <td>
                          {order.paymentStatus && (
                            <span className="badge bg-success">PAID</span>
                          )}
                          {!order.paymentStatus && (
                            <span className="badge bg-danger">NOT PAID</span>
                          )}
                        </td>
                        <td>{order.paymentMethod?.toUpperCase()}</td>
                        <td>
                          {order.status === "pending" && (
                            <span className="badge bg-warning">PENDING</span>
                          )}
                          {(order.status === "partiallyComplete" ||
                            order.status === "processing") && (
                            <span className="badge bg-primary">
                              PROCESSING...
                            </span>
                          )}
                          {order.status === "completed" && (
                            <span className="badge bg-success">COMPLETED</span>
                          )}
                        </td>
                        <td>
                          {new Date(order.createdAt).toLocaleDateString()}
                        </td>
                        <td>
                          <button
                            className="btn btn-primary btn-sm px-2 py-1"
                            onClick={() => setActiveOrder(order)}
                          >
                            <i className="bi-eye"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                    {isLoading && (
                      <tr>
                        <td colSpan="9">
                          <div className="text-center">
                            <Loader />
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <nav className="mt-3">
            <ul className="pagination justify-content-center">
              {pager.previous && (
                <li
                  className="page-item bg-light rounded"
                  onClick={() => readOrders(pager.previous, null, pager.sortBy)}
                >
                  <a className="page-link">
                    <i className="bi bi-chevron-double-left"></i>
                  </a>
                </li>
              )}

              {pager.previous && (
                <li
                  className="page-item bg-light rounded"
                  onClick={() => readOrders(pager.previous, null, pager.sortBy)}
                >
                  <a className="page-link">{pager.page - 1}</a>
                </li>
              )}

              <li className="page-item bg-primary rounded">
                <a className="page-link" href="#">
                  {pager.page}
                </a>
              </li>

              {pager.next && (
                <li
                  className="page-item bg-light rounded"
                  onClick={() => readOrders(pager.next, null, pager.sortBy)}
                >
                  <a className="page-link">{pager.page + 1}</a>
                </li>
              )}

              {pager.next && (
                <li
                  className="page-item bg-light rounded"
                  onClick={() => readOrders(pager.next, null, pager.sortBy)}
                >
                  <a className="page-link" href="#">
                    <i className="bi bi-chevron-double-right"></i>
                  </a>
                </li>
              )}
            </ul>
          </nav>
        </Fragment>
      ) : (
        <Fragment>
          <button
            className="btn btn-warning btn-sm mb-3"
            onClick={() => setActiveOrder(null)}
          >
            <i className="bi-arrow-left"></i> Go Back
          </button>
          <div className="card">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center w-100">
                <h5 className="card-title">Order Products</h5>
              </div>
              <br />
              <p> USER MESSAGE: {activeOrder.message}</p>
            </div>

            <div className="card-body px-0 pb-0">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Title</th>
                      <th>Label</th>
                      <th>Category</th>
                      <th>Price</th>
                      <th>Duration</th>
                      <th>FullFill Mode</th>
                      <th>Quantity</th>
                      <th>Creds</th>
                      <th>status</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {activeOrder.products.map((product) => (
                      <tr key={product._id}>
                        <td>{product._id}</td>
                        <td>{product.title}</td>
                        <td>{product.label}</td>
                        <td>{product.categories.join(",")}</td>
                        <td>${product.price}</td>
                        <td>{product.duration} Days</td>
                        <td>{product.fullFillmode.toUpperCase()}</td>
                        <td>{product.quantity}</td>
                        <td>{product.creds.join(",").substr(0, 20)}</td>
                        <td>
                          {product.creds.length > 0 && (
                            <span className="badge bg-success">COMPLETED</span>
                          )}
                          {product.creds.length === 0 && (
                            <span className="badge bg-warning">PENDING</span>
                          )}
                        </td>
                        <td>
                          <button
                            className="btn btn-primary btn-sm me-2 px-2 py-1"
                            onClick={async () => {
                              setActiveProduct(product);
                              await creds.setValue(product.creds.join(","));
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#fullFillProduct"
                          >
                            <i className="bi-hdd-fill"></i>
                          </button>
                          <button
                            className="btn btn-info btn-sm me-2 px-2 py-1"
                            onClick={() => {
                              setActiveProduct(product);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#changeExpiry"
                          >
                            <i className="bi-calendar2-date"></i>
                          </button>
                          <button
                            className="btn btn-danger btn-sm px-2 py-1"
                            onClick={async () => {
                              setActiveProduct(product);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#delete"
                          >
                            <i className="bi-trash"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Fragment>
      )}

      {/* Fullfill Modal */}
      <Modal id="fullFillProduct" title="Fullfill Order">
        <form onSubmit={fullfillOrder}>
          {messages.fullfill}
          <input type="hidden" name="orderId" value={activeOrder?._id} />
          <input type="hidden" name="productId" value={activeProduct?._id} />
          <div className="form-group mb-3">
            <label htmlFor="name">Credentials</label>
            <div
              name="creds"
              id="creds"
              style={{
                height: "300px",
              }}
              className="form-control"
            ></div>
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary">
              Submit {loaders.fullfill && <Loader />}
            </button>
          </div>
        </form>
      </Modal>

      {/* Change Expiry */}
      <Modal id="changeExpiry" title="Change Order Product Expiry">
        <form onSubmit={updateExpiry}>
          {messages.expiry}
          <input type="hidden" name="orderId" value={activeOrder?._id} />
          <input type="hidden" name="productId" value={activeProduct?._id} />
          <div className="form-group mb-3">
            <label htmlFor="name">Expiry</label>
            {activeProduct?.expiry && (
              <input
                type="date"
                name="expiry"
                id="expiry"
                className="form-control"
                value={new Date(activeProduct?.expiry)
                  ?.toISOString()
                  ?.slice(0, 10)}
                onChange={(e) =>
                  setActiveProduct({ ...activeProduct, expiry: e.target.value })
                }
                required
              />
            )}
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary">
              Submit {loaders.expiry && <Loader />}
            </button>
          </div>
        </form>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal id="delete" title="Delete FAQ" style={{ zIndex: 1200 }}>
        {messages.delete}
        <h3 className="text-center">Are you sure you want to delete?</h3>
        <div className="form-group mt-4 text-center">
          <button className="btn btn-primary m-2" data-bs-dismiss="modal">
            Go back
          </button>
          <button
            className="btn btn-danger"
            onClick={deleteOrderProduct}
            data-bs-dismiss="modal"
          >
            Continue {loaders.delete && <Loader />}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Orders;
