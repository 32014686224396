import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import PageLoader from "../components/PageLoader";
import Modal from "../components/Modal";
import Loader from "../components/Loader";
import Alert from "../components/Alert";

const Tickets = () => {
  const [tickets, setTickets] = useState([]);
  const [ticket, setTicket] = useState({});
  const [action, setAction] = useState("open");
  const [loaders, setLoaders] = useState({
    isLoading: true,
    create: false,
    status: false,
    send: false,
  });
  const [messages, setMessages] = useState({
    create: "",
    status: "",
    send: "",
  });
  const [pager, setPager] = useState({
    total: 0,
    page: 1,
    limit: 10,
    next: null,
    previous: null,
  });

  useEffect(() => {
    readTickets();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setMessages({
        create: "",
        status: "",
        send: "",
      });
    }, 3000);
  }, [messages]);

  const readTickets = async (page = 1, search = null, sortBy = "createdAt") => {
    setLoaders({
      ...loaders,
      isLoading: true,
    });

    const params = {
      page: page || 1,
      search: search || "",
      sortBy: sortBy || "createdAt",
    };
    await axios
      .post(server + "/v1/ticket/read", params, config)
      .then((rsp) => {
        setTickets(rsp.data.payload.records);
        setPager(rsp.data.payload.pager);

        setTicket(
          rsp.data.payload.records.find((tik) => tik._id === ticket._id)
        );
      })
      .catch((err) => {
        console.log(err);
      });

    setLoaders({
      ...loaders,
      isLoading: false,
    });
  };

  const setTicketStatus = async () => {
    setLoaders({
      ...loaders,
      status: true,
    });
    const params = {
      ticketId: ticket._id,
    };
    await axios
      .post(server + "/v1/ticket/" + action, params, config)
      .then((rsp) => {
        setMessages({
          ...messages,
          status: <Alert className="success" message={rsp.data.message} />,
        });
        readTickets();
      })
      .catch((err) => {
        setMessages({
          ...messages,
          status: (
            <Alert className="danger" message={err.response?.data?.message} />
          ),
        });
      });

    setLoaders({
      ...loaders,
      status: false,
    });
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    setLoaders({
      ...loaders,
      send: true,
    });
    const params = {
      ticketId: ticket._id,
      message: e.target.message.value,
    };
    await axios
      .post(server + "/v1/ticket/sendMessage", params, config)
      .then((rsp) => {
        setMessages({
          ...messages,
          send: <Alert className="success" message={rsp.data.message} />,
        });
        readTickets();
      })
      .catch((err) => {
        setMessages({
          ...messages,
          send: (
            <Alert className="danger" message={err.response?.data?.message} />
          ),
        });
      });

    e.target.reset();

    setLoaders({
      ...loaders,
      send: false,
    });
  };

  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <Header title="Users" />

      <Fragment>
        <div className="card">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center w-100">
              <h5 className="card-title">Users</h5>
              <div className="btn-group">
                <select
                  className="form-control d-none me-2"
                  defaultValue="createdAt"
                  onChange={(e) => readTickets(1, null, e.target.value)}
                >
                  <option value="createdAt">Created At</option>
                  <option value="updatedAt">Updated At</option>
                  <option value="_id">Order ID</option>
                  <option value="price">Price</option>
                </select>
                <input
                  type="text"
                  className="form-control"
                  style={{
                    maxWidth: "200px",
                  }}
                  placeholder="Search..."
                  onChange={(e) => readTickets(1, e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="card-body px-0 pb-0">
            <div className="table-responsive">
              <table className="table table-striped mb-2">
                <thead>
                  <tr>
                    <th>Ticket ID</th>
                    <th>Order ID</th>
                    <th>Subject</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Updated At</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {tickets.map((ticket) => (
                    <tr key={ticket._id}>
                      <td>{ticket._id}</td>
                      <td>{ticket.order._id}</td>
                      <td>{ticket.subject}</td>
                      <td>{ticket.order.email}</td>
                      <td>
                        {ticket.status === "open" ? (
                          <span className="badge bg-success">OPEN</span>
                        ) : (
                          <span className="badge bg-danger">CLOSED</span>
                        )}
                      </td>
                      <td>{new Date(ticket.updatedAt).toDateString()}</td>
                      <td>{new Date(ticket.createdAt).toDateString()}</td>
                      <td
                        style={{
                          width: "100px",
                        }}
                      >
                        <button
                          className={`btn btn-${
                            ticket.status === "open" ? "danger" : "primary"
                          } btn-sm py-1 px-2 me-2`}
                          onClick={() => {
                            setTicket(ticket);
                            setAction(
                              ticket.status === "open" ? "close" : "open"
                            );
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#setStatus"
                        >
                          {ticket.status === "open" ? (
                            <i className="bi-x-lg" />
                          ) : (
                            <i className="bi-check-lg" />
                          )}
                        </button>
                        <button
                          className="btn btn-warning btn-sm py-1 px-2"
                          onClick={() => {
                            setTicket(ticket);
                            setAction(
                              ticket.status === "open" ? "close" : "open"
                            );
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#message"
                        >
                          <i className="bi-chat-left"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                  {loaders.isLoading && (
                    <tr>
                      <td colSpan="8">
                        <div className="text-center">
                          <Loader />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <nav className="mt-3">
          <ul className="pagination justify-content-center">
            {pager.previous && (
              <li
                className="page-item bg-light rounded"
                onClick={() => readTickets(pager.previous, null, pager.sortBy)}
              >
                <a className="page-link">
                  <i className="bi bi-chevron-double-left"></i>
                </a>
              </li>
            )}

            {pager.previous && (
              <li
                className="page-item bg-light rounded"
                onClick={() => readTickets(pager.previous, null, pager.sortBy)}
              >
                <a className="page-link">{pager.page - 1}</a>
              </li>
            )}

            <li className="page-item bg-primary rounded">
              <a className="page-link" href="#">
                {pager.page}
              </a>
            </li>

            {pager.next && (
              <li
                className="page-item bg-light rounded"
                onClick={() => readTickets(pager.next, null, pager.sortBy)}
              >
                <a className="page-link">{pager.page + 1}</a>
              </li>
            )}

            {pager.next && (
              <li
                className="page-item bg-light rounded"
                onClick={() => readTickets(pager.next, null, pager.sortBy)}
              >
                <a className="page-link" href="#">
                  <i className="bi bi-chevron-double-right"></i>
                </a>
              </li>
            )}
          </ul>
        </nav>
      </Fragment>

      {/* Ticket Messages */}
      <Modal id="message" title={"Ticket Messages - " + ticket?.subject}>
        {/* messages */}
        <div
          className="card border mb-3"
          style={{
            height: "400px",
            overflowY: "auto",
          }}
        >
          <div className="card-body">
            {ticket?.messages?.map((message) => (
              <div key={message._id}>
                <div
                  className={
                    "row " +
                    (message.username === "Admin" &&
                      "justify-content-end text-end")
                  }
                >
                  <div
                    className="col-auto"
                    style={{
                      maxWidth: "80%",
                    }}
                  >
                    <div
                      className={`card border bg-${
                        message.username === "Admin" ? "primary" : "light"
                      } mb-2`}
                    >
                      <div className="card-body pb-1 px-2 pt-1 text-dark">
                        {message.message}
                        <p
                          className="p-0 m-0 text-gray"
                          style={{
                            fontSize: "8px",
                          }}
                        >
                          {new Date(message.timestamp).toLocaleString()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {action === "close" && (
          <form onSubmit={sendMessage}>
            {messages.send}
            <div className="form-group mb-3">
              <label htmlFor="name">Write Message</label>
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="3"
                className="form-control"
              ></textarea>
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-primary">
                Send Message {loaders.send && <Loader />}
              </button>
            </div>
          </form>
        )}
      </Modal>

      {/* Close/Open Ticket */}
      <Modal id="setStatus" title={action + " ticket"}>
        <div className="text-center">
          {messages.status}
          <h3>Are you sure you want to {action} ticket?</h3>
          <div className="form-group mt-4">
            <button className="btn btn-primary m-2" data-bs-dismiss="modal">
              Go back
            </button>
            <button
              className="btn btn-danger"
              onClick={setTicketStatus}
              data-bs-dismiss="modal"
            >
              Continue {loaders.status && <Loader />}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Tickets;
