import React, { useState } from "react";
import { Link } from "react-router-dom";

const Sidebar = (props) => {
  const [tab, setTab] = useState(window.location.href.split("/")[3] || "/");

  const setMode = (mode) => {
    localStorage.setItem("themeMode", mode);
    if (mode === "light")
      document
        .getElementById("theme")
        .setAttribute("href", "/assets/css/theme.min.css");
    else
      document
        .getElementById("theme")
        .setAttribute("href", "/assets/css/theme-dark.min.css");
  };

  const hideSidebar = () => {
    const sidebar = document.getElementById("sidebar");
    if (sidebar) {
      sidebar.style.marginLeft = "-17.2rem";
    }
  };

  return (
    <aside
      id="sidebar"
      className="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered bg-white  "
    >
      <div className="navbar-vertical-container">
        <div className="navbar-vertical-footer-offset">
          {/* Logo */}
          <a className="navbar-brand" href="/" aria-label="Front">
            <img
              className="navbar-brand-logo rounded"
              src="/assets/img/logo/logo-dark.svg"
              alt="..."
            />

            <img
              className="navbar-brand-logo-mini"
              src="/assets/img/logo/main.png"
              alt="..."
            />
          </a>

          <button
            type="button"
            className="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler"
            style={{ opacity: 1 }}
            onClick={hideSidebar}
            id="sidebarHideToggle"
          >
            <i
              className="bi-arrow-bar-left navbar-toggler-short-align"
              data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>'
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title=""
              data-bs-original-title="Collapse"
              aria-label="Collapse"
            ></i>
            <i
              className="bi-arrow-bar-right navbar-toggler-full-align"
              data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>'
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title=""
              data-bs-original-title="Expand"
              aria-label="Expand"
            ></i>
          </button>

          <div className="navbar-vertical-content">
            <div
              id="navbarVerticalMenu"
              className="nav nav-pills nav-vertical card-navbar-nav"
            >
              {/* Collapse */}
              <div className="nav-item">
                <Link
                  to="/"
                  className={tab === "/" ? "nav-link active" : "nav-link"}
                  onClick={() => setTab("/")}
                >
                  <i className="bi-house-door nav-icon" />
                  <span className="nav-link-title">Dashboard</span>
                </Link>
              </div>
              <div className="nav-item">
                <Link
                  to="/orders"
                  className={tab === "orders" ? "nav-link active" : "nav-link"}
                  onClick={() => setTab("orders")}
                >
                  <i className="bi-cart nav-icon" />
                  <span className="nav-link-title">Orders</span>
                </Link>
              </div>

              <div className="nav-item">
                <Link
                  to="/requests"
                  className={
                    tab === "requests" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => setTab("requests")}
                >
                  <i className="bi-box-arrow-in-down nav-icon" />
                  <span className="nav-link-title">Requests</span>
                </Link>
              </div>

              <div className="nav-item">
                <Link
                  to="/categories"
                  className={
                    tab === "categories" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => setTab("categories")}
                >
                  <i className="bi-list nav-icon" />
                  <span className="nav-link-title">Categories</span>
                </Link>
              </div>

              <div className="nav-item">
                <Link
                  to="/products"
                  className={
                    tab === "products" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => setTab("products")}
                >
                  <i className="bi-box nav-icon" />
                  <span className="nav-link-title">Products</span>
                </Link>
              </div>

              <div className="nav-item">
                <Link
                  to="/users"
                  className={tab === "users" ? "nav-link active" : "nav-link"}
                  onClick={() => setTab("users")}
                >
                  <i className="bi-people nav-icon" />
                  <span className="nav-link-title">Users</span>
                </Link>
              </div>

              <div className="nav-item">
                <Link
                  to="/stock"
                  className={tab === "stock" ? "nav-link active" : "nav-link"}
                  onClick={() => setTab("stock")}
                >
                  <i className="bi-hdd-stack nav-icon" />
                  <span className="nav-link-title">Stock</span>
                </Link>
              </div>

              <div className="nav-item">
                <Link
                  to="/coupons"
                  className={tab === "coupons" ? "nav-link active" : "nav-link"}
                  onClick={() => setTab("coupons")}
                >
                  <i className="bi-bookmark nav-icon" />
                  <span className="nav-link-title">Coupons</span>
                </Link>
              </div>

              <div className="nav-item">
                <Link
                  to="/tickets"
                  className={tab === "tickets" ? "nav-link active" : "nav-link"}
                  onClick={() => setTab("tickets")}
                >
                  <i className="bi-ticket-detailed nav-icon" />
                  <span className="nav-link-title">Tickets</span>
                </Link>
              </div>

              <div className="nav-item">
                <Link
                  to="/feedbacks"
                  className={
                    tab === "feedbacks" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => setTab("feedbacks")}
                >
                  <i className="bi-star-fill nav-icon" />
                  <span className="nav-link-title">Feedbacks</span>
                </Link>
              </div>

              <div className="nav-item">
                <Link
                  to="/faq"
                  className={tab === "faq" ? "nav-link active" : "nav-link"}
                  onClick={() => setTab("faq")}
                >
                  <i className="bi-info-circle nav-icon" />
                  <span className="nav-link-title">FAQs</span>
                </Link>
              </div>
              <div className="nav-item">
                <Link
                  to="/tos"
                  className={tab === "tos" ? "nav-link active" : "nav-link"}
                  onClick={() => setTab("tos")}
                >
                  <i className="bi-file-earmark-text nav-icon" />
                  <span className="nav-link-title">Terms of Service</span>
                </Link>
              </div>
              <div className="nav-item">
                <Link
                  to="/privacy-policy"
                  className={
                    tab === "privacy-policy" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => setTab("privacy-policy")}
                >
                  <i className="bi-file-earmark-text nav-icon" />
                  <span className="nav-link-title">Privacy Policy</span>
                </Link>
              </div>

              <div className="nav-item">
                <Link
                  to="/website"
                  className={tab === "website" ? "nav-link active" : "nav-link"}
                  onClick={() => setTab("website")}
                >
                  <i className="bi-globe nav-icon" />
                  <span className="nav-link-title">Website</span>
                </Link>
              </div>
            </div>
          </div>
          {/* End Content */}
          {/* Footer */}
          <div className="navbar-vertical-footer">
            <ul className="navbar-vertical-footer-list">
              <li className="navbar-vertical-footer-list-item">
                {/* Style Switcher */}
                <div className="dropdown dropup">
                  <button
                    type="button"
                    className="btn btn-ghost-secondary btn-icon rounded-circle"
                    id="selectThemeDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-dropdown-animation
                  >
                    <i className="bi-palette" />
                  </button>
                  <div
                    className="dropdown-menu navbar-dropdown-menu navbar-dropdown-menu-borderless"
                    aria-labelledby="selectThemeDropdown"
                  >
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      className="dropdown-item"
                      data-icon="bi-brightness-high"
                      data-value="default"
                      onClick={() => {
                        setMode("light");
                      }}
                    >
                      <i className="bi-brightness-high me-2" />
                      <span className="text-truncate">Light</span>
                    </span>
                    <span
                      className="dropdown-item"
                      data-icon="bi-moon"
                      data-value="dark"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setMode("dark");
                      }}
                    >
                      <i className="bi-moon me-2" />
                      <span className="text-truncate" title="Dark">
                        Dark
                      </span>
                    </span>
                  </div>
                </div>
                {/* End Style Switcher */}
              </li>
            </ul>
          </div>
          {/* End Footer */}
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
