import React, { PureComponent } from "react";
import Header from "../components/Header";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import Requests from "../utils/requests";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { quillToolbarOptions } from "../utils/helper";

const requests = new Requests();

export default class PrivacyPolicy extends PureComponent {
  state = {
    doc: {},
    loader: "",
    message: "",
  };

  componentDidMount = async () => {
    const docs = await requests.readDocs();
    console.log(docs.find((doc) => doc.name === "Privacy Policy"));
    this.setState({
      doc: docs.find((doc) => doc.name === "Privacy Policy"),
    });

    const doc = document.getElementsByClassName("ql-snow");
    if (doc.length > 0) {
      for (let i = 0; i < doc.length; i++) {
        doc[i].style.border = "unset";
      }
    }
  };

  update = async (e) => {
    this.setState({
      loader: <Loader />,
    });

    const { doc } = this.state;
    console.log(doc);
    const { error, message } = await requests.updateDoc(
      "Privacy Policy",
      doc.description
    );

    this.setState({
      loader: "",
      message: (
        <Alert className={error ? "danger" : "success"} message={message} />
      ),
    });
  };

  render() {
    const { doc, loader, message } = this.state;

    return (
      <div className="main-content">
        <Header title="FAQs" />
        <div className="container-fluid mt-5">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h4 className="card-header-title">Privacy Policy</h4>
                </div>
                <div className="card-body">
                  {message}
                  <ReactQuill
                    theme="snow"
                    modules={{ toolbar: quillToolbarOptions }}
                    value={doc.description}
                    onChange={(value) => {
                      this.setState({
                        doc: {
                          description: value,
                        },
                      });
                    }}
                    style={{
                      height: "300px",
                      marginBottom: "70px",
                    }}
                  />
                  <div className="text-end">
                    <button className="btn btn-primary" onClick={this.update}>
                      Update {loader}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
