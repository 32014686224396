import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Loader from "../components/Loader";
import Requests from "../utils/requests";
const requests = new Requests();

export default class Feedbacks extends PureComponent {
  state = {
    feedbacks: [],
    pager: {
      page: 1,
    },
    isLoading: true,
    search: "",

    // create FAQ
    createLoader: "",
  };

  componentDidMount = () => {
    this.readFeedbacks();
  };

  readFeedbacks = async (page = 1, search = this.state.search) => {
    this.setState({
      isLoading: true,
    });

    const { feedbacks, pager } = await requests.readFeedbacks(page, search);
    this.setState({
      feedbacks,
      pager,
      isLoading: false,
    });
  };

  deleteFeedback = async (id) => {
    await requests.deleteFeedback(id);
    this.readFeedbacks(this.state.pager.page);
  };

  hideModal = (id) => {
    const dom = document.getElementById("close" + id);
    if (dom) dom.click();
  };

  render() {
    const { feedbacks, pager, isLoading } = this.state;

    return (
      <div className="main-content">
        <Header title="Feedbacks" />
        <div className="container-fluid mt-5">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h4 className="card-header-title">Product Feedbacks</h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Username</th>
                          <th>Message</th>
                          <th>Rating</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      {!isLoading ? (
                        <tbody>
                          {feedbacks.map((feedback, index) => (
                            <tr key={index}>
                              <td>
                                {(pager.page - 1) * pager.limit + (index + 1)}
                              </td>
                              <td>{feedback.order?.email || "N/A - OLD"}</td>
                              <td
                                style={{
                                  maxWidth: "400px",
                                  textAlign: "justify",
                                }}
                              >
                                {feedback.message}
                              </td>
                              <td>{feedback.stars}</td>
                              <td>
                                {new Date(feedback.createdAt).toDateString()}
                              </td>
                              <td>
                                <button
                                  className="btn btn-danger btn-sm px-2 py-1"
                                  onClick={() =>
                                    this.deleteFeedback(feedback._id)
                                  }
                                >
                                  <i className="bi-trash"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan={6}>
                              <div className="text-center">
                                <Loader />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
              <nav className="mt-3">
                <ul className="pagination justify-content-center">
                  {pager.previous && (
                    <li
                      className="page-item bg-light rounded"
                      onClick={() =>
                        this.readFeedbacks(pager.previous, null, pager.sortBy)
                      }
                    >
                      <Link to="#" className="page-link">
                        <i className="bi bi-chevron-double-left"></i>
                      </Link>
                    </li>
                  )}

                  {pager.previous && (
                    <li
                      className="page-item bg-light rounded"
                      onClick={() =>
                        this.readFeedbacks(pager.previous, null, pager.sortBy)
                      }
                    >
                      <Link to="#" className="page-link">
                        {pager.page - 1}
                      </Link>
                    </li>
                  )}

                  <li className="page-item bg-primary rounded">
                    <Link to="#" className="page-link" href="#">
                      {pager.page}
                    </Link>
                  </li>

                  {pager.next && (
                    <li
                      className="page-item bg-light rounded"
                      onClick={() =>
                        this.readFeedbacks(pager.next, null, pager.sortBy)
                      }
                    >
                      <Link to="#" className="page-link">
                        {pager.page + 1}
                      </Link>
                    </li>
                  )}

                  {pager.next && (
                    <li
                      className="page-item bg-light rounded"
                      onClick={() =>
                        this.readFeedbacks(pager.next, null, pager.sortBy)
                      }
                    >
                      <Link to="#" className="page-link" href="#">
                        <i className="bi bi-chevron-double-right"></i>
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
