import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import { BarChart } from "../utils/helper";

const Dashboard = () => {
  const [stats, setStats] = useState({});

  useEffect(() => {
    readDashboard();
    readRevenueStats();
  }, []);

  // read dashboard
  const readDashboard = async () => {
    await axios
      .get(server + "/v1/admin/dashboard", config)
      .then((rsp) => {
        setStats(rsp.data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const readRevenueStats = async (filter = "week") => {
    var e = document.getElementById("revenue");
    var canvas = document.createElement("CANVAS");
    while (e.firstChild) {
      e.removeChild(e.firstChild);
    }
    e.appendChild(canvas);
    canvas.classList.add("chart-canvas");

    var data = [];
    await axios
      .get(server + "/v1/admin/revenueChart/" + filter, config)
      .then((rsp) => {
        let rspData = rsp.data.payload;
        if (filter === "year") rspData = rspData.reverse();

        var labels = [];
        var amount = [];
        rspData.forEach((element) => {
          labels.push(element._id);
          amount.push(element.revenue.toFixed(2));
        });
        data = {
          labels,
          datasets: [
            {
              label: "Amount",
              data: amount,
              borderColor: "white",
              backgroundColor: "#387dff",
              fill: true,
              tension: 0.2,
            },
          ],
        };
      });

    BarChart(canvas, data);
  };

  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <Header title="Dashboard" />

      {/* Dashboard Stats */}
      <div className="row">
        {/* users */}
        <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
          <span
            className="card card-hover-shadow h-100"
            style={{
              cursor: "pointer",
            }}
          >
            <div className="card-body">
              <h6 className="card-subtitle">Total Users</h6>
              <div className="row align-items-center gx-2 mb-1">
                <div className="col-6">
                  <h2 className="card-title text-inherit">{stats.users}</h2>
                </div>
                <div className="col-6">
                  <div className="chartjs-custom" style={{ height: "3rem" }}>
                    <div className="w-100 text-end fs-2rem">
                      <i className="bi-people"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </div>

        {/* orders */}
        <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
          <span
            className="card card-hover-shadow h-100"
            style={{
              cursor: "pointer",
            }}
          >
            <div className="card-body">
              <h6 className="card-subtitle">Total Orders</h6>
              <div className="row align-items-center gx-2 mb-1">
                <div className="col-6">
                  <h2 className="card-title text-inherit">{stats.orders}</h2>
                </div>
                <div className="col-6">
                  <div className="chartjs-custom" style={{ height: "3rem" }}>
                    <div className="w-100 text-end fs-2rem">
                      <i className="bi-cart"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </div>

        {/* revenue */}
        <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
          <span
            className="card card-hover-shadow h-100"
            style={{
              cursor: "pointer",
            }}
          >
            <div className="card-body">
              <h6 className="card-subtitle">Total Revenue</h6>
              <div className="row align-items-center gx-2 mb-1">
                <div className="col-6">
                  <h2 className="card-title text-inherit">
                    ${stats.revenue?.toFixed(2)}
                  </h2>
                </div>
                <div className="col-6">
                  <div className="chartjs-custom" style={{ height: "3rem" }}>
                    <div className="w-100 text-end fs-2rem">
                      <i className="bi-currency-dollar"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </div>

        {/* tickets */}
        <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
          <span
            className="card card-hover-shadow h-100"
            style={{
              cursor: "pointer",
            }}
          >
            <div className="card-body">
              <h6 className="card-subtitle">Open Tickets</h6>
              <div className="row align-items-center gx-2 mb-1">
                <div className="col-6">
                  <h2 className="card-title text-inherit">
                    {stats.openTickets}
                  </h2>
                </div>
                <div className="col-6">
                  <div className="chartjs-custom" style={{ height: "3rem" }}>
                    <div className="w-100 text-end fs-2rem">
                      <i className="bi-ticket-detailed"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </div>

        {/* products */}
        <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
          <span
            className="card card-hover-shadow h-100"
            style={{
              cursor: "pointer",
            }}
          >
            <div className="card-body">
              <h6 className="card-subtitle">Products</h6>
              <div className="row align-items-center gx-2 mb-1">
                <div className="col-6">
                  <h2 className="card-title text-inherit">{stats.products}</h2>
                </div>
                <div className="col-6">
                  <div className="chartjs-custom" style={{ height: "3rem" }}>
                    <div className="w-100 text-end fs-2rem">
                      <i className="bi-box"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </div>

        {/* Product Options */}
        <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
          <span
            className="card card-hover-shadow h-100"
            style={{
              cursor: "pointer",
            }}
          >
            <div className="card-body">
              <h6 className="card-subtitle">Product Options</h6>
              <div className="row align-items-center gx-2 mb-1">
                <div className="col-6">
                  <h2 className="card-title text-inherit">
                    {stats.productOptions}
                  </h2>
                </div>
                <div className="col-6">
                  <div className="chartjs-custom" style={{ height: "3rem" }}>
                    <div className="w-100 text-end fs-2rem">
                      <i className="bi-box"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>

      {/* Revenue Chart */}
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center w-100">
            <h5 className="card-title">Revenue Chart</h5>
            <select
              name=""
              id=""
              className="form-control"
              style={{
                maxWidth: "200px",
              }}
              onChange={(e) => {
                readRevenueStats(e.target.value);
              }}
            >
              <option value="week">Last 7 Days</option>
              <option value="month">Last 30 Days</option>
              <option value="year">Last 1 Year</option>
            </select>
          </div>
        </div>
        <div className="card-body">
          <div className="chart" id="revenue"></div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
