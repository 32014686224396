import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import PageLoader from "../components/PageLoader";
import Modal from "../components/Modal";
import Loader from "../components/Loader";
import { ReactSortable } from "react-sortablejs";
import Requests from "../utils/requests";
const requests = new Requests();

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [loaders, setLoaders] = useState({
    create: false,
    update: false,
    delete: false,
    arrange: false,
  });

  useEffect(() => {
    readCategories();
  }, []);

  // read dashboard
  const readCategories = async () => {
    setIsLoading(true);

    await axios
      .get(server + "/v1/category/read")
      .then((rsp) => {
        setCategories(rsp.data.payload);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const create = async (e) => {
    e.preventDefault();
    setLoaders({ ...loaders, create: true });

    const form = e.target;
    const data = new FormData(form);

    await axios
      .post(server + "/v1/category/create", data, config)
      .then((rsp) => {
        readCategories();
      })
      .catch((err) => {
        console.log(err);
      });

    form.reset();
    setLoaders({ ...loaders, create: false });
    hideModal("create");
  };

  const update = async (e) => {
    e.preventDefault();

    setLoaders({ ...loaders, update: true });

    const form = e.target;
    const data = new FormData(form);

    await axios
      .put(server + "/v1/category/update", data, config)
      .then((rsp) => {
        readCategories();
      })
      .catch((err) => {
        console.log(err);
      });

    form.reset();
    setLoaders({ ...loaders, update: false });
    hideModal("update");
  };

  const deleteCategory = async (e) => {
    e.preventDefault();
    const form = e.target;
    setLoaders({ ...loaders, delete: false });

    await axios
      .delete(server + "/v1/category/delete/" + category._id, config)
      .then((rsp) => {
        readCategories();
      })
      .catch((err) => {
        console.log(err);
      });

    form.reset();
    setLoaders({ ...loaders, delete: false });
    hideModal("deleteCategory");
  };

  const hideModal = (id) => {
    const dom = document.getElementById("close" + id);
    if (dom) dom.click();
  };

  const arrangeCategories = async () => {
    setLoaders({ ...loaders, arrange: true });
    const cats = [];
    for (let i = 0; i < categories.length; i++) {
      cats.push({ id: categories[i]._id });
    }
    await requests.arrangeCategories(cats);
    setLoaders({ ...loaders, arrange: false });
  };

  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <Header title="Categories" />

      <div className="d-flex justify-content-end align-items-center w-100 mb-3">
        <button
          className="btn btn-primary btn-sm me-2"
          data-bs-toggle="modal"
          data-bs-target="#create"
        >
          <i className="bi-plus"></i> Add Category
        </button>
        <button
          className="btn btn-sm btn-warning"
          data-bs-toggle="modal"
          data-bs-target="#arrange"
        >
          <i className="bi-arrows-move"></i>
        </button>
      </div>

      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center w-100">
            <h5 className="card-title">Categories</h5>
            <div className="btn-group">
              <input
                type="text"
                className="form-control"
                style={{
                  maxWidth: "200px",
                }}
                placeholder="Search..."
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="card-body px-0 pb-0">
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Category ID</th>
                  <th>Category Name</th>
                  <th>Color</th>
                  <th>Action</th>
                </tr>
              </thead>

              {!isLoading ? (
                <tbody>
                  {categories
                    .filter((c) =>
                      c.name.toLowerCase().includes(search.toLocaleLowerCase())
                    )
                    .map((category) => (
                      <tr key={category._id}>
                        <td>{category._id}</td>
                        <td>
                          <span
                            key={category._id}
                            className="badge text-black me-1"
                            style={{
                              background: category.color,
                            }}
                          >
                            {category.name}
                          </span>
                        </td>
                        <td>{category.color}</td>
                        <td>
                          <button
                            className="btn btn-warning btn-sm me-1 px-2 py-1"
                            onClick={() => setCategory(category)}
                            data-bs-toggle="modal"
                            data-bs-target="#update"
                          >
                            <i className="bi-pen"></i>
                          </button>
                          <button
                            className="btn btn-danger btn-sm px-2 py-1"
                            onClick={() => setCategory(category)}
                            data-bs-toggle="modal"
                            data-bs-target="#delete"
                          >
                            <i className="bi-trash"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="3">
                      <PageLoader />
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>

      {/* Create */}
      <Modal id="create" title="Create Category">
        <form onSubmit={create}>
          <div className="form-group mb-3">
            <label htmlFor="name">Category Name</label>
            <input
              type="text"
              name="name"
              className="form-control"
              autoFocus
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="name">Category Color(Hex Code)</label>
            <input type="text" name="color" className="form-control" />
          </div>

          <div className="form-group">
            <button type="submit" className="btn btn-primary">
              Submit {loaders.create && <Loader />}
            </button>
          </div>
        </form>
      </Modal>

      {/* Update */}
      <Modal id="update" title="Update Category">
        <form onSubmit={update}>
          <input type="hidden" name="id" value={category._id} />
          <div className="form-group mb-3">
            <label htmlFor="name">Category Name</label>
            <input
              type="text"
              name="name"
              className="form-control"
              autoFocus
              value={category.name}
              onChange={(e) =>
                setCategory({ ...category, name: e.target.value })
              }
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="name">Category Color</label>
            <input
              type="text"
              name="color"
              className="form-control"
              autoFocus
              value={category.color}
              onChange={(e) =>
                setCategory({ ...category, color: e.target.value })
              }
              required
            />
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary">
              Submit {loaders.update && <Loader />}
            </button>
          </div>
        </form>
      </Modal>

      {/* Delete */}
      <Modal id="delete" title="Delete Category">
        <div className="text-center">
          <h3>Are you sure you want to delete?</h3>
          <div className="form-group mt-4">
            <button className="btn btn-primary m-2" data-bs-dismiss="modal">
              Go back
            </button>
            <button
              className="btn btn-danger"
              onClick={deleteCategory}
              data-bs-dismiss="modal"
            >
              Continue {loaders.delete && <Loader />}
            </button>
          </div>
        </div>
      </Modal>

      {/* arrange categories */}
      <Modal id="arrange" title="Arrange Product Options">
        <ReactSortable
          key={"categories"}
          list={categories}
          setList={(cats) => {
            setCategories(cats);
          }}
          animation={200}
          group={{ name: "categories" }}
          style={{
            overflowY: "auto",
          }}
        >
          {categories.map((cat, index) => (
            <div key={index} className="card mb-3">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>{cat.name}</h5>
                </div>
              </div>
            </div>
          ))}
        </ReactSortable>
        <div className="text-end">
          <button className="btn btn-primary" onClick={arrangeCategories}>
            Save {loaders.arrange && <Loader />}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Categories;
