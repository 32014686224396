import React from "react";
import Header from "../components/Header";

import Announcement from "./website/Announcement";
import PaymentGateway from "./website/PaymentGateway";
import SocialLink from "./website/SocialLinks";

const Website = () => {
  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <Header title="Website" />

      <div className="row gy-4">
        <div className="col-md-6">
          {/* Announcement Component */}
          <Announcement />
        </div>
        <div className="col-md-6">
          {/* Payment Gateway Component */}
          <PaymentGateway />
        </div>
        <div className="col-md-6">
          {/* Social Link Component */}
          <SocialLink />
        </div>
      </div>
    </div>
  );
};

export default Website;
