import React, { PureComponent } from "react";
import Header from "../components/Header";
import Modal from "../components/Modal";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import Requests from "../utils/requests";
import PageLoader from "../components/PageLoader";
const requests = new Requests();

export default class Coupon extends PureComponent {
  state = {
    coupons: [],
    isLoading: true,

    // current Coupon
    coupon: "",
    code: "",
    amount: 0,
    discount: 0,

    loader: "",
    message: "",
  };

  componentDidMount = () => {
    document.title = "Frequently Asked Questions - Proxies.gg";
    this.readCoupons();
    setInterval(() => {
      this.setState({
        message: "",
      });
    }, 3000);
  };

  readCoupons = async () => {
    this.setState({
      coupons: await requests.getCoupons(),
      isLoading: false,
    });
  };

  // create Coupon
  createCoupon = async (e) => {
    e.preventDefault();
    this.setState({ loader: <Loader /> });

    const form = e.target;

    const { error, message } = await requests.createCoupon(
      form.code.value,
      form.amount.value,
      form.discount.value
    );

    this.setState({
      message: (
        <Alert className={error ? "danger" : "success"} message={message} />
      ),
    });

    this.setState({ loader: "" });
    this.readCoupons();

    e.target.reset();
  };

  // onChangeUpdate Form
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // update Coupon
  updateCoupon = async (e) => {
    e.preventDefault();
    this.setState({ loader: <Loader /> });

    const form = e.target;

    const { error, message } = await requests.updateCoupon(
      this.state.coupon._id,
      form.code.value,
      form.amount.value,
      form.discount.value
    );

    this.setState({
      message: (
        <Alert className={error ? "danger" : "success"} message={message} />
      ),
    });

    this.setState({ loader: "" });
    this.readCoupons();

    e.target.reset();
  };

  // Delete Coupon
  deleteCoupon = async () => {
    this.setState({ loader: <Loader /> });

    const { error, message } = await requests.deleteCoupon(
      this.state.coupon._id
    );

    this.setState({
      message: (
        <Alert className={error ? "danger" : "success"} message={message} />
      ),
    });
    this.readCoupons();

    this.setState({ loader: "" });
  };

  hideModal = (id) => {
    const dom = document.getElementById("close" + id);
    if (dom) dom.click();
  };

  render() {
    const { coupons, isLoading } = this.state;
    const { code, amount, discount } = this.state;
    const { message, loader } = this.state;

    return (
      <div className="main-content">
        <Header title="Coupons" />
        <div className="container-fluid mt-5">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h4 className="card-header-title">Coupons</h4>
                  <div className="dropdown">
                    <button
                      className="btn btn-sm btn-primary"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#create"
                      onClick={() => this.setState({ message: "" })}
                    >
                      + New Coupon
                    </button>
                  </div>
                </div>
                <div className="card-body px-0 pb-0">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Coupon ID</th>
                          <th>Code</th>
                          <th>Amount Left</th>
                          <th>Discount</th>
                          <th>Created At</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      {!isLoading ? (
                        <tbody>
                          {coupons.map((coupon) => (
                            <tr key={coupon._id}>
                              <td>{coupon._id}</td>
                              <td>{coupon.code}</td>
                              <td>{coupon.amount}</td>
                              <td>{coupon.discount}</td>
                              <td>
                                {new Date(coupon.createdAt).toDateString()}
                              </td>
                              <td>
                                <button
                                  className="btn btn-warning btn-sm me-2 px-2 py-1"
                                  onClick={() =>
                                    this.setState({
                                      coupon: coupon,
                                      code: coupon.code,
                                      amount: coupon.amount,
                                      discount: coupon.discount,
                                    })
                                  }
                                  data-bs-toggle="modal"
                                  data-bs-target="#update"
                                >
                                  <i className="bi-pen" />
                                </button>
                                <button
                                  className="btn btn-danger btn-sm px-2 py-1"
                                  onClick={() =>
                                    this.setState({
                                      coupon: coupon,
                                    })
                                  }
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete"
                                >
                                  <i className="bi-trash" />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="6">
                              <PageLoader />
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modals */}
        {/* Create Coupon */}
        <Modal id="create" title="Create Coupon">
          <form onSubmit={this.createCoupon}>
            {message}
            <div className="form-group mb-3">
              <label>Coupon Code</label>
              <input
                type="text"
                className="form-control"
                name="code"
                id="code"
                required
              />
            </div>
            <div className="form-group mb-3">
              <label>Amount</label>
              <input
                type="number"
                className="form-control"
                name="amount"
                id="amount"
                required
              />
            </div>
            <div className="form-group mb-3">
              <label>Discount(%)</label>
              <input
                type="number"
                className="form-control"
                name="discount"
                id="discount"
                required
              />
            </div>
            <div className="form-group d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                Add Coupon{loader}
              </button>
            </div>
          </form>
        </Modal>

        {/* Update Coupon */}
        <Modal id="update" title="Update Coupon">
          <form onSubmit={this.updateCoupon}>
            {message}
            <div className="form-group mb-3">
              <label>Coupon Code</label>
              <input
                type="text"
                className="form-control"
                name="code"
                value={code}
                onChange={this.onChange}
                id="code"
                required
              />
            </div>
            <div className="form-group mb-3">
              <label>Amount</label>
              <input
                type="number"
                className="form-control"
                name="amount"
                value={amount}
                onChange={this.onChange}
                id="amount"
                required
              />
            </div>
            <div className="form-group mb-3">
              <label>Discount(%)</label>
              <input
                type="number"
                className="form-control"
                name="discount"
                value={discount}
                onChange={this.onChange}
                id="discount"
                required
              />
            </div>
            <div className="form-group d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                Update Coupon{loader}
              </button>
            </div>
          </form>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal id="delete" title="Delete Coupon" style={{ zIndex: 1200 }}>
          {message}
          <h3 className="text-center">Are you sure you want to delete?</h3>
          <div className="form-group mt-4 text-center">
            <button className="btn btn-primary m-2" data-bs-dismiss="modal">
              Go back
            </button>
            <button
              className="btn btn-danger"
              onClick={this.deleteCoupon}
              // data-bs-dismiss="modal"
            >
              Continue {loader}
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}
