import React from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { Route, Routes } from "react-router-dom";

import Dashboard from "../source/Dashboard";
import Orders from "../source/Orders";
import Categories from "../source/Categories";
import Products from "../source/Products";
import Users from "../source/Users";
import Tickets from "../source/Tickets";
import FAQs from "../source/FAQs";
import Website from "../source/Website";
import Coupon from "../source/Coupon";
import Requests from "../source/Requests";
import Feedbacks from "../source/Feedbacks";
import Settings from "../source/Settings";
import Tos from "../source/Tos";
import PrivacyPolicy from "../source/PrivacyPolicy";
import Stock from "../source/Stock";

const DashboardRoutes = () => {
  return (
    <>
      <Navbar />
      <Sidebar />
      <main id="content" role="main" className="main">
        <Routes>
          <Route path="/stock" element={<Stock />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/tos" element={<Tos />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/feedbacks" element={<Feedbacks />} />
          <Route path="/requests" element={<Requests />} />
          <Route path="/coupons" element={<Coupon />} />
          <Route path="/website" element={<Website />} />
          <Route path="/faq" element={<FAQs />} />
          <Route path="/tickets" element={<Tickets />} />
          <Route path="/users" element={<Users />} />
          <Route path="/products" element={<Products />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/*" element={<Dashboard />} />
        </Routes>
      </main>
    </>
  );
};

export default DashboardRoutes;
