import { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import AuthRouter from "./routes/AuthRouter";
import DashboardRoutes from "./routes/DashboardRoutes";
import { Route, Routes } from "react-router-dom";
import axios from "axios";
import { server, config } from "./env";
import PageLoader from "./components/PageLoader";

function App() {
  useEffect(() => {
    setMode(localStorage.getItem("themeMode"));
  }, []);

  const [isLogin, setIsLogin] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    checkUserLogin();
  }, []);

  const checkUserLogin = async (token) => {
    await axios(server + "/v1/admin/access", config)
      .then((rsp) => {
        setIsLogin(true);
        setIsLoaded(true);
      })
      .catch((err) => {
        setIsLogin(false);
        setIsLoaded(true);
      });
  };

  const setMode = (mode) => {
    localStorage.setItem("themeMode", mode);
    if (mode === "light")
      document
        .getElementById("theme")
        .setAttribute("href", "/assets/css/theme.min.css");
    else
      document
        .getElementById("theme")
        .setAttribute("href", "/assets/css/theme-dark.min.css");
  };

  return isLoaded ? (
    isLogin ? (
      <Routes>
        <Route path="/*" element={<DashboardRoutes />} />
      </Routes>
    ) : (
      <Routes>
        <Route path="/*" element={<AuthRouter />} />
      </Routes>
    )
  ) : (
    <PageLoader />
  );
}

export default App;
