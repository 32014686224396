import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { server, config } from "../../env";
import PageLoader from "../../components/PageLoader";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";

const Announcement = () => {
  const [announcement, setAnnouncement] = useState("");
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [updateLoader, setUpdateLoader] = useState("");

  useEffect(() => {
    readAnnouncement();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 2000);
  }, [message]);

  const readAnnouncement = async () => {
    await axios
      .get(server + "/v1/announcement/read")
      .then((rsp) => {
        setAnnouncement(rsp.data.payload?.text);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const update = async (e) => {
    e.preventDefault();
    setUpdateLoader(<Loader />);
    await axios
      .put(
        server + "/v1/announcement/update",
        {
          text: announcement,
        },
        config
      )
      .then((rsp) => {
        setMessage(<Alert className="success" message={rsp.data.message} />);
      })
      .catch((err) => {
        setMessage(
          <Alert className="danger" message={err.response?.data?.message} />
        );
        console.log(err);
      });

    setUpdateLoader("");
  };

  return (
    <Fragment>
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">Announcements</h5>
        </div>
        {!loading ? (
          <div className="card-body">
            <form onSubmit={update}>
              {message}
              <div className="form-group mb-3">
                <label htmlFor="title">Announcement Text</label>
                <textarea
                  name="announcement"
                  id="announcement"
                  cols="30"
                  rows="5"
                  className="form-control"
                  value={announcement}
                  onChange={(e) => setAnnouncement(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-primary">
                  Update {updateLoader}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="p-5">
            <PageLoader />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Announcement;
