import { server, config } from "../env";
import axios from "axios";

export default class Requests {
  async getCoupons() {
    var coupons = [];
    await axios
      .get(server + "/v1/coupon/read", config)
      .then((rsp) => {
        coupons = rsp.data.payload;
      })
      .catch((err) => {});
    return coupons;
  }

  async createCoupon(code, amount, discount) {
    var error = null;
    var message = "";
    await axios
      .post(server + "/v1/coupon/create", { code, amount, discount }, config)
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }

  async updateCoupon(id, code, amount, discount) {
    var error = null;
    var message = "";
    await axios
      .put(
        server + "/v1/coupon/update/" + id,
        { code, amount, discount },
        config
      )
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }

  async deleteCoupon(id) {
    var error = null;
    var message = "";
    await axios
      .delete(server + "/v1/coupon/delete/" + id, config)
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }

  async getRequests(page = 1, search = "", sortBy = "createdAt", cancelToken) {
    var requests = [];
    var pager = {
      total: 0,
      page: 1,
      limit: 10,
      next: null,
      previous: null,
    };
    await axios
      .post(
        server + "/v1/request/read",
        { page, search, sortBy, cancelToken },
        config
      )
      .then((rsp) => {
        requests = rsp.data.payload.records;
        pager = rsp.data.payload.pager;
      })
      .catch((err) => {});
    return { requests, pager };
  }

  async fullfillRequest(id, creds) {
    var error = null;
    var message = "";
    await axios
      .post(server + "/v1/request/fullfill", { id, creds }, config)
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }

  async autoFullfillRequest(id) {
    var error = null;
    var message = "";
    await axios
      .post(server + "/v1/request/autoFullfill", { id }, config)
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }

  async rejectRequest(id, msg) {
    var error = null;
    var message = "";
    await axios
      .post(server + "/v1/request/reject", { id, message: msg }, config)
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }

  async readProductFeedbacks(page = 1, search, sortBy = "createdAt") {
    var feedbacks = [];
    var pager = {};
    await axios
      .post(server + "/v1/feedback/product", { page, search, sortBy }, config)
      .then((rsp) => {
        feedbacks = rsp.data.payload.records;
        pager = rsp.data.payload.pager;
      })
      .catch((err) => {});
    return { feedbacks, pager };
  }

  async readFeedbacks(page = 1, search = "", sortBy = "createdAt") {
    var feedbacks = [];
    var pager = {};
    await axios
      .post(server + "/v1/feedback/readAdmin", { page, search, sortBy }, config)
      .then((rsp) => {
        feedbacks = rsp.data.payload.records;
        pager = rsp.data.payload.pager;
      })
      .catch((err) => {});
    return { feedbacks, pager };
  }

  async deleteFeedback(id) {
    var error = null;
    var message = "";
    await axios
      .delete(server + "/v1/feedback/delete/" + id, config)
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }

  async updatePassword(password) {
    var error = null;
    var message = "";
    await axios
      .post(server + "/v1/admin/updatePassword", { password }, config)
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    console.log(error, message);

    return { error, message };
  }

  async deleteOrderProduct(id, productId) {
    var error = null;
    var message = "";
    await axios
      .post(
        server + "/v1/order/product/delete",
        {
          id,
          productId,
        },
        config
      )
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }

  async getOrderRequests(orderId, productId, productOptionId) {
    var requests = [];
    await axios
      .post(
        server + "/v1/request/getRequests",
        { orderId, productId, productOptionId },
        config
      )
      .then((rsp) => {
        requests = rsp.data.payload;
      })
      .catch((err) => {});
    return requests;
  }

  async blockUnblockReplacements(id, status) {
    var error = null;
    var message = "";
    await axios
      .post(
        server + "/v1/request/blockUnblockReplacements",
        { id, status },
        config
      )
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }

  async updateExpiryDate(orderId, productId, expiry) {
    var error = null;
    var message = "";
    await axios
      .post(
        server + "/v1/order/product/updateExpiry",
        { orderId, productId, expiry },
        config
      )
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }

  async readDocs() {
    var doc = {};
    await axios
      .get(server + "/v1/doc/read", config)
      .then((rsp) => {
        doc = rsp.data.payload;
      })
      .catch((err) => {});
    return doc;
  }

  async updateDoc(name, description) {
    var error = null;
    var message = "";
    await axios
      .put(server + "/v1/doc/update", { name, description }, config)
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }

  async arrangeProducts(sortNumbers) {
    var error = null;
    var message = "";
    await axios
      .put(server + "/v1/product/arrange", { sortNumbers }, config)
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }

  async arrangeProductOptions(sortNumbers) {
    var error = null;
    var message = "";
    await axios
      .put(server + "/v1/product/option/arrange", { sortNumbers }, config)
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }

  async arrangeCategories(sortNumbers) {
    var error = null;
    var message = "";
    await axios
      .put(server + "/v1/category/arrange", { sortNumbers }, config)
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }

  async generateKeys(optionId, amount, duration, keys) {
    var error = null;
    var message = "";
    await axios
      .post(
        server + "/v1/product/generateKeys",
        { optionId, amount, duration, keys },
        config
      )
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }

  async getStock() {
    var options = [];
    await axios
      .get(server + "/v1/product/stock", config)
      .then((rsp) => {
        options = rsp.data.payload;
      })
      .catch((err) => {});
    return options;
  }
}
