import React from "react";
import Header from "../components/Header";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import Requests from "../utils/requests";
const requests = new Requests();

const Settings = () => {
  const [loader, setLoader] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const updatePassword = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);
    const { error, message } = await requests.updatePassword(
      e.target.password.value
    );

    setMessage(
      <Alert className={error ? "danger" : "success"} message={message} />
    );
    setLoader("");
  };

  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <Header title="Settings" />

      <div className="row gy-4">
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">
              <h4>Change Password</h4>
            </div>
            <div className="card-body">
              <form onSubmit={updatePassword}>
                {message}
                <div className="form-group mb-3">
                  <label htmlFor="">New Password</label>
                  <input
                    type="password"
                    name="password"
                    id=""
                    minLength={8}
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary">
                    Change Password {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
