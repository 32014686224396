import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { server, config } from "../../env";
import PageLoader from "../../components/PageLoader";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";

const PaymentGateway = () => {
  const [paymentGateways, setPaymentGateways] = useState("");
  const [paymentGateway, setPaymentGateway] = useState({});
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [updateLoader, setUpdateLoader] = useState("");

  useEffect(() => {
    readPaymentGateways();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 2000);
  }, [message]);

  const readPaymentGateways = async () => {
    await axios
      .get(server + "/v1/payment_gateway/read")
      .then((rsp) => {
        setPaymentGateways(rsp.data.payload);
        setPaymentGateway(rsp.data.payload[0]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const update = async (e) => {
    e.preventDefault();
    setUpdateLoader(<Loader />);
    await axios
      .put(
        server + "/v1/payment_gateway/update",
        {
          name: e.target.name.value,
          status: e.target.status.value === "true",
        },
        config
      )
      .then((rsp) => {
        setMessage(<Alert className="success" message={rsp.data.message} />);
      })
      .catch((err) => {
        setMessage(
          <Alert className="danger" message={err.response?.data?.message} />
        );
        console.log(err);
      });

    setUpdateLoader("");
  };

  return (
    <Fragment>
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">Payment Gateways</h5>
        </div>
        {!loading ? (
          <div className="card-body">
            <form onSubmit={update}>
              {message}
              <div className="form-group mb-3">
                <label htmlFor="title">Select Gateway</label>
                <select
                  name="name"
                  id="name"
                  className="form-control"
                  value={paymentGateway?.name}
                  onChange={(e) =>
                    setPaymentGateway({
                      ...paymentGateway,
                      name: e.target.value,
                    })
                  }
                >
                  {paymentGateways.map((paymentGateway) => (
                    <option
                      key={paymentGateway.name}
                      value={paymentGateway.name}
                    >
                      {paymentGateway.name.toUpperCase()}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="status">Status</label>
                <select
                  name="status"
                  id="status"
                  className="form-control"
                  value={paymentGateway?.status}
                  onChange={(e) =>
                    setPaymentGateway({
                      ...paymentGateway,
                      status: e.target.value,
                    })
                  }
                >
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </div>
              <button type="submit" className="btn btn-primary">
                Update {updateLoader}
              </button>
            </form>
          </div>
        ) : (
          <div className="card-body">
            <PageLoader />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default PaymentGateway;
