import React, { PureComponent } from "react";
import Header from "../components/Header";
import Modal from "../components/Modal";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import Requests from "../utils/requests";
import { Link } from "react-router-dom";
import axios from "axios";
const req = new Requests();

export default class Request extends PureComponent {
  state = {
    requests: [],
    pager: {},

    // current Request
    request: "",
    search: "",
    currentCreds: [],
    currentHistory: [],
    status: false,

    // update Request
    loader: "",
    autoLoader: "",
    message: "",
  };

  componentDidMount = () => {
    this.readRequests();
  };

  readRequests = async (page = 1, search = "", sortBy = "createdAt") => {
    this.axiosCancelSource = axios.CancelToken.source();
    this.setState({ isLoading: true });
    const { requests, pager } = await req.getRequests(
      page,
      search,
      sortBy,
      this.axiosCancelSource.token
    );
    this.setState({ requests, pager, isLoading: false });
  };

  readHistory = async (orderId, productId, productOptionId) => {
    this.setState({
      currentHistory: await req.getOrderRequests(
        orderId,
        productId,
        productOptionId
      ),
    });
  };

  // onChangeUpdate Form
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // update Request
  fullfillRequest = async (e) => {
    e.preventDefault();
    this.setState({ loader: <Loader /> });

    const { error, message } = await req.fullfillRequest(
      this.state.request._id,
      e.target.creds.value.split("\n")
    );

    this.readRequests(
      this.state.pager.page,
      this.state.pager.search,
      this.state.pager.sortBy
    );

    this.setState({
      loader: "",
      message: (
        <Alert className={error ? "danger" : "success"} message={message} />
      ),
    });
    this.hideModal("update");
  };

  // update Request
  autoFullfillRequest = async () => {
    this.setState({ autoLoader: <Loader /> });

    const { error, message } = await req.autoFullfillRequest(
      this.state.request._id
    );

    this.readRequests(
      this.state.pager.page,
      this.state.pager.search,
      this.state.pager.sortBy
    );

    this.setState({
      autoLoader: "",
      message: (
        <Alert className={error ? "danger" : "success"} message={message} />
      ),
    });
  };

  // reject request
  rejectRequest = async (e) => {
    e.preventDefault();
    this.setState({ loader: <Loader /> });

    const { error, message } = await req.rejectRequest(
      this.state.request._id,
      e.target.message.value
    );

    const { page, sortBy } = this.state.pager;
    const search = this.state.search;
    this.readRequests(page, search, sortBy);

    this.hideModal("rejectModal");

    this.setState({
      loader: "",
      message: (
        <Alert className={error ? "danger" : "success"} message={message} />
      ),
    });
  };

  hideModal = (id) => {
    const dom = document.getElementById("close" + id);
    if (dom) dom.click();
  };

  blockUnblockRequest = async () => {
    this.setState({ loader: <Loader /> });

    const { error, message } = await req.blockUnblockReplacements(
      this.state.request._id,
      !this.state.status
    );

    this.readRequests(
      this.state.pager.page,
      this.state.pager.search,
      this.state.pager.sortBy
    );

    this.setState({
      loader: "",
      message: (
        <Alert className={error ? "danger" : "success"} message={message} />
      ),
    });
    this.hideModal("blockUnblock");
  };

  render() {
    const { requests, pager, request } = this.state;
    const { isLoading, autoLoader, loader, message } = this.state;
    const { currentCreds, currentHistory, status } = this.state;

    return (
      <div className="main-content">
        <Header title="Requests" />
        <div className="container-fluid mt-5">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h4 className="card-header-title">Reequests/Replacements</h4>
                  <div className="btn-group">
                    <select
                      className="form-control me-2"
                      defaultValue="createdAt"
                      onChange={(e) =>
                        this.readRequests(1, null, e.target.value)
                      }
                    >
                      <option value="createdAt">Created at</option>
                      <option value="status">Status</option>
                      <option value="_id">Request ID</option>
                    </select>
                    <input
                      type="text"
                      className="form-control"
                      style={{
                        maxWidth: "200px",
                      }}
                      placeholder="Search..."
                      onChange={(e) => {
                        this.setState({
                          search: e.target.value,
                        });
                        this.readRequests(1, e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          {/* <th>Request ID</th> */}
                          <th>Order ID</th>
                          <th>Email</th>
                          <th>Product Title</th>
                          <th>Product Option</th>
                          <th>Status</th>
                          <th>Remarks</th>
                          <th>Created At</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {requests.map((request) => (
                          <tr key={request._id}>
                            {/* <td>{request._id}</td> */}
                            {/* <td>{request._id.substr(0, 5)}..</td> */}
                            <td>{request.order._id}</td>
                            <td>{request.order?.email}</td>
                            <td>
                              {
                                request.order?.products?.find(
                                  (p) =>
                                    p.product === request.product &&
                                    p.productOption === request.productOption
                                )?.title
                              }
                            </td>
                            <td>
                              {
                                request.order?.products?.find(
                                  (p) =>
                                    p.product === request.product &&
                                    p.productOption === request.productOption
                                )?.subtitle
                              }
                            </td>
                            <td>
                              {request.status === "pending" && (
                                <span className="badge bg-warning">
                                  Pending
                                </span>
                              )}
                              {request.status === "completed" && (
                                <span className="badge bg-success">
                                  Completed
                                </span>
                              )}
                              {request.status === "rejected" && (
                                <span className="badge bg-danger">
                                  Rejected
                                </span>
                              )}
                            </td>
                            <td>{request.remarks}</td>
                            <td>
                              {new Date(request.createdAt).toDateString()}
                            </td>
                            <td>
                              <div className="d-flex">
                                <button
                                  className="btn btn-primary btn-sm me-1 px-2 py-1"
                                  onClick={() =>
                                    this.readHistory(
                                      request.order._id,
                                      request.product,
                                      request.productOption
                                    )
                                  }
                                  data-bs-toggle="modal"
                                  data-bs-target="#viewHistory"
                                >
                                  <i className="bi-clock-history"></i>
                                </button>
                                <button
                                  className="btn btn-warning btn-sm me-1 px-2 py-1"
                                  onClick={() =>
                                    this.setState({
                                      request: request,
                                      currentCreds:
                                        request.order?.products?.find(
                                          (p) =>
                                            p.product === request.product &&
                                            p.productOption ===
                                              request.productOption
                                        )?.creds,
                                    })
                                  }
                                  data-bs-toggle="modal"
                                  data-bs-target="#update"
                                >
                                  <i className="bi-pen"></i>
                                </button>
                                <button
                                  className="btn btn-danger btn-sm me-1 px-2 py-1"
                                  onClick={() =>
                                    this.setState({
                                      request: request,
                                    })
                                  }
                                  data-bs-toggle="modal"
                                  data-bs-target="#rejectModal"
                                >
                                  <i className="bi-x-lg"></i>
                                </button>
                                <button
                                  className={
                                    request.order?.products?.find(
                                      (p) =>
                                        p.product === request.product &&
                                        p.productOption ===
                                          request.productOption
                                    )?.isReplacement
                                      ? "btn btn-danger btn-sm px-2 py-1"
                                      : "btn btn-info btn-sm px-2 py-1"
                                  }
                                  onClick={() =>
                                    this.setState({
                                      request,
                                      status: request.order?.products?.find(
                                        (p) =>
                                          p.product === request.product &&
                                          p.productOption ===
                                            request.productOption
                                      )?.isReplacement,
                                    })
                                  }
                                  data-bs-toggle="modal"
                                  data-bs-target="#blockUnblock"
                                >
                                  {request.order?.products?.find(
                                    (p) =>
                                      p.product === request.product &&
                                      p.productOption === request.productOption
                                  )?.isReplacement ? (
                                    <i className="bi-slash-circle-fill"></i>
                                  ) : (
                                    <i className="bi-check-lg"></i>
                                  )}
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                        {isLoading && (
                          <tr>
                            <td colSpan="9">
                              <div className="text-center">
                                <Loader />
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <nav className="mt-3">
                <ul className="pagination justify-content-center">
                  {pager.previous && (
                    <li
                      className="page-item bg-light rounded"
                      onClick={() =>
                        this.readRequests(pager.previous, null, pager.sortBy)
                      }
                    >
                      <Link to="#" className="page-link">
                        <i className="bi bi-chevron-double-left"></i>
                      </Link>
                    </li>
                  )}

                  {pager.previous && (
                    <li
                      className="page-item bg-light rounded"
                      onClick={() =>
                        this.readRequests(pager.previous, null, pager.sortBy)
                      }
                    >
                      <Link to="#" className="page-link">
                        {pager.page - 1}
                      </Link>
                    </li>
                  )}

                  <li className="page-item bg-primary rounded">
                    <Link to="#" className="page-link" href="#">
                      {pager.page}
                    </Link>
                  </li>

                  {pager.next && (
                    <li
                      className="page-item bg-light rounded"
                      onClick={() =>
                        this.readRequests(pager.next, null, pager.sortBy)
                      }
                    >
                      <Link to="#" className="page-link">
                        {pager.page + 1}
                      </Link>
                    </li>
                  )}

                  {pager.next && (
                    <li
                      className="page-item bg-light rounded"
                      onClick={() =>
                        this.readRequests(pager.next, null, pager.sortBy)
                      }
                    >
                      <Link to="#" className="page-link" href="#">
                        <i className="bi bi-chevron-double-right"></i>
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>

        {/* History Modal */}
        <Modal
          id="viewHistory"
          title="Product Replacement History"
          className="modal-lg"
        >
          <div className="modal-body">
            <div className="table-responsive">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>STATUS</th>
                    <th>DATE</th>
                    <th>INDEX</th>
                    <th>CREDENTIALS</th>
                  </tr>
                </thead>
                <tbody>
                  {currentHistory.map((request, index) => (
                    <tr>
                      <td>{request._id}</td>
                      <td>
                        {request.status === "pending" && (
                          <span className="badge bg-warning ms-2">PENDING</span>
                        )}
                        {request.status === "completed" && (
                          <span className="badge bg-success ms-2">
                            COMPLETED
                          </span>
                        )}
                        {request.status === "rejected" && (
                          <span className="badge bg-danger ms-2">REJECTED</span>
                        )}
                      </td>
                      <td>{new Date(request.createdAt).toDateString()}</td>
                      <td>{request.credIndex}</td>
                      <td>
                        {request.status === "completed"
                          ? request.creds.join(", ")
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
                  {currentHistory.length === 0 && (
                    <tr>
                      <td colSpan={4}>
                        <div className="text-center">
                          <h6>No history found</h6>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>

        {/* Update Request */}
        <Modal id="update" title="Update Request">
          <form onSubmit={this.fullfillRequest}>
            {message}
            <div className="form-group mb-3">
              <label>Credentials (index {request?.credIndex + 1})</label>
              <textarea
                name="creds"
                id="creds"
                cols="30"
                rows="10"
                className="form-control"
                value={currentCreds.join("\n")}
                onChange={(e) =>
                  this.setState({ currentCreds: e.target.value.split("\n") })
                }
              ></textarea>
            </div>
            <div className="form-group d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.autoFullfillRequest}
              >
                Fullfill From Stock {autoLoader}
              </button>
              <button type="submit" className="btn btn-primary">
                Submit Form {loader}
              </button>
            </div>
          </form>
        </Modal>

        {/*  Confirmation Modal */}
        <Modal
          id="blockUnblock"
          title={status ? "Block Replacements" : "Unblock Replacements"}
          style={{ zIndex: 1200 }}
        >
          <h3 className="text-center">
            Are you sure you want to{" "}
            {status ? "Block Replacements" : "Unblock Replacements"}?
          </h3>
          <div className="form-group mt-4 text-center">
            <button className="btn btn-primary m-2" data-bs-dismiss="modal">
              Go back
            </button>
            <button
              className="btn btn-danger"
              onClick={this.blockUnblockRequest}
              data-bs-dismiss="modal"
            >
              Continue {loader && <Loader />}
            </button>
          </div>
        </Modal>

        <Modal id="rejectModal" title="Reject Request" style={{ zIndex: 1200 }}>
          <form onSubmit={this.rejectRequest}>
            <div className="form-group mb-3">
              <label>Message</label>
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="10"
                className="form-control"
              ></textarea>
            </div>
            <div className="form-group d-flex justify-content-between">
              <button type="submit" className="btn btn-primary">
                Reject Request {loader}
              </button>
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}
