import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { server, config } from "../../env";
import PageLoader from "../../components/PageLoader";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";

const SocialLink = () => {
  const [socialLinks, setSocialLinks] = useState("");
  const [socialLink, setSocialLink] = useState({});
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [updateLoader, setUpdateLoader] = useState("");

  useEffect(() => {
    readSocialLinks();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 2000);
  }, [message]);

  const readSocialLinks = async () => {
    await axios
      .get(server + "/v1/social_link/read")
      .then((rsp) => {
        setSocialLinks(rsp.data.payload);
        setSocialLink(rsp.data.payload[0]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const update = async (e) => {
    e.preventDefault();
    setUpdateLoader(<Loader />);
    await axios
      .put(server + "/v1/social_link/update", socialLink, config)
      .then((rsp) => {
        setMessage(<Alert className="success" message={rsp.data.message} />);
      })
      .catch((err) => {
        setMessage(
          <Alert className="danger" message={err.response?.data?.message} />
        );
        console.log(err);
      });

    setUpdateLoader("");
  };

  return (
    <Fragment>
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">Social Links</h5>
        </div>
        {!loading ? (
          <div className="card-body">
            <form onSubmit={update}>
              {message}
              <div className="form-group mb-3">
                <label htmlFor="title">Select Link</label>
                <select
                  name="name"
                  id="name"
                  className="form-control"
                  value={socialLink?.name}
                  onChange={(e) =>
                    setSocialLink({
                      name: e.target.value,
                      link: socialLinks.find(
                        (link) => link.name === e.target.value
                      )?.link,
                    })
                  }
                >
                  {socialLinks.map((socialLink) => (
                    <option key={socialLink.name} value={socialLink.name}>
                      {socialLink.name.toUpperCase()}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="status">Link</label>
                <input
                  type="text"
                  name="link"
                  className="form-control"
                  value={socialLink?.link}
                  onChange={(e) =>
                    setSocialLink({
                      name: socialLink?.name,
                      link: e.target.value,
                    })
                  }
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Update {updateLoader}
              </button>
            </form>
          </div>
        ) : (
          <div className="card-body">
            <PageLoader />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default SocialLink;
