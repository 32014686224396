import { Fragment, useState, useEffect } from "react";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import Requests from "../utils/requests";
const requests = new Requests();

export default function Stock() {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await requests.getStock();
      setOptions(data);
    })();
  }, []);

  return (
    <Fragment>
      <div className="content container-fluid">
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Product Title</th>
                    <th>Sub Option Title</th>
                    <th>Replacement</th>
                    <th>Always. Pen.</th>
                    <th>Stock</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {options
                    .sort((a, b) => (a.stock.length > b.stock.length ? 1 : -1))
                    .map((op) => (
                      <tr>
                        <th>{op.product.title}</th>
                        <th>{op.title}</th>
                        <td>{op.isReplacement ? "Yes" : "No"}</td>
                        <td>{op.isAlwaysPending ? "Yes" : "No"}</td>
                        <td>{op.stock?.length || 0}</td>
                        <td></td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
