import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Header from "../components/Header";
import Modal from "../components/Modal";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import { server, config, checkAccess } from "../env";

export default class FAQs extends PureComponent {
  state = {
    faqs: [],
    backupFaqs: [],

    // current FAQ
    faq: "",

    // create FAQ
    createLoader: "",
    createMessage: "",

    // update FAQ
    updateLoader: "",
    updateMessage: "",

    // delete FAQ
    deleteMessage: "",
    deleteLoader: "",
  };

  componentDidMount = () => {
    this.readFAQs();
  };

  readFAQs = async () => {
    await axios.get(server + "/v1/faq/read").then((rsp) => {
      this.setState({
        faqs: rsp.data.payload,
        backupFaqs: rsp.data.payload,
      });
    });
  };

  // create FAQ
  createFAQ = async (e) => {
    e.preventDefault();
    this.setState({
      createLoader: <Loader />,
    });

    const params = Array.from(e.target.elements)
      .filter((el) => el.name)
      .reduce((a, b) => ({ ...a, [b.name]: b.value }), {});

    await axios
      .post(server + "/v1/faq/create", params, config)
      .then(async (rsp) => {
        await this.readFAQs();
        this.setState({
          createLoader: "",
          createMessage: (
            <Alert className="success" message={rsp.data.message} />
          ),
        });
        this.hideModal("create");
      })
      .catch((err) => {
        checkAccess(err);
        if (err.response) {
          this.setState({
            createMessage: (
              <Alert className="danger" message={err.response.data.message} />
            ),
          });
        }
        this.setState({
          createLoader: "",
        });
      });
    e.target.reset();
  };

  // onChangeUpdate Form
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // update FAQ
  updateFAQ = async (e) => {
    e.preventDefault();
    this.setState({
      updateLoader: <Loader />,
    });

    const params = Array.from(e.target.elements)
      .filter((el) => el.name)
      .reduce((a, b) => ({ ...a, [b.name]: b.value }), {});

    const data = { ...params };
    data.id = this.state.faq;

    await axios
      .put(server + "/v1/faq/update", data, config)
      .then(async (rsp) => {
        await this.readFAQs();
        this.setState({
          updateLoader: "",
          updateMessage: (
            <Alert className="success" message={rsp.data.message} />
          ),
        });
        this.hideModal("update");
      })
      .catch((err) => {
        checkAccess(err);
        if (err.response) {
          this.setState({
            updateMessage: (
              <Alert className="danger" message={err.response.data.message} />
            ),
          });
        }
        this.setState({
          updateLoader: "",
        });
      });
    e.target.reset();
  };

  // Delete FAQ
  deleteFAQ = async () => {
    await axios
      .delete(server + "/v1/faq/delete/" + this.state.faq, config)
      .then(async (rsp) => {
        await this.readFAQs();

        this.setState({
          deleteLoader: "",
          deleteMessage: (
            <Alert className="success" message={rsp.data.message} />
          ),
          faqs: this.state.backupFaqs.filter(
            (faq) => faq.id !== this.state.faq
          ),
        });
        this.hideModal("delete");
      })
      .catch((err) => {
        checkAccess(err);
        if (err.response) {
          this.setState({
            deleteMessage: (
              <Alert className="danger" message={err.response.data.message} />
            ),
          });
        }
        this.setState({
          deleteLoader: "",
        });
      });
  };

  hideModal = (id) => {
    const dom = document.getElementById("close" + id);
    if (dom) dom.click();
  };

  render() {
    const { faqs } = this.state;
    const { createMessage, createLoader } = this.state;
    const { updateMessage, updateLoader } = this.state;
    const { question, answer } = this.state;
    const { deleteMessage, deleteLoader } = this.state;
    return (
      <div className="main-content">
        <Header title="FAQs" />
        <div className="container-fluid mt-5">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h4 className="card-header-title">
                    Frequently Asked Questions
                  </h4>
                  <div className="dropdown">
                    <button
                      className="btn btn-sm btn-primary"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#create"
                      onClick={() => this.setState({ createMessage: "" })}
                    >
                      New FAQ
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="accordion" id="accordionFlushExample">
                    {faqs.map((faq, index) => (
                      <div className="accordion-item" key={index}>
                        <h2 className="accordion-header" id={"head" + index}>
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#col" + index}
                            aria-expanded="false"
                            aria-controls={"col" + index}
                          >
                            {faq.question}
                          </button>
                        </h2>
                        <div
                          id={"col" + index}
                          className="accordion-collapse collapse"
                          aria-labelledby={"head" + index}
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">{faq.answer}</div>
                          <h2>
                            <Link
                              to="#"
                              className="btn btn-sm btn-primary mx-3"
                              onClick={() =>
                                this.setState({
                                  faq: faq._id,
                                  updateMessage: "",
                                  question: faq.question,
                                  answer: faq.answer,
                                })
                              }
                              data-bs-toggle="modal"
                              data-bs-target="#update"
                            >
                              <i className="fe fe-edit-3"></i> Edit
                            </Link>
                            <Link
                              to="#"
                              className="btn btn-sm btn-danger"
                              onClick={() =>
                                this.setState({
                                  faq: faq._id,
                                  deleteMessage: "",
                                })
                              }
                              data-bs-toggle="modal"
                              data-bs-target="#delete"
                            >
                              <i className="fe fe-trash"></i> Delete
                            </Link>
                          </h2>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modals */}
        {/* Create FAQ */}
        <Modal id="create" title="Create FAQ">
          <form onSubmit={this.createFAQ}>
            {createMessage}
            <div className="form-group mb-3">
              <label>Question</label>
              <input
                type="text"
                className="form-control"
                name="question"
                id="question"
                required
              />
            </div>
            <div className="form-group mb-3">
              <label>Answer</label>
              <input
                type="text"
                className="form-control"
                name="answer"
                id="answer"
                required
              />
            </div>
            <div className="form-group d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                Add FAQ{createLoader}
              </button>
            </div>
          </form>
        </Modal>

        {/* Update FAQ */}
        <Modal id="update" title="Update FAQ">
          <form onSubmit={this.updateFAQ}>
            {updateMessage}
            <div className="form-group mb-3">
              <label>Question</label>
              <input
                type="text"
                className="form-control"
                name="question"
                value={question}
                onChange={this.onChange}
                id="question"
                required
              />
            </div>
            <div className="form-group mb-3">
              <label>Answer</label>
              <input
                type="text"
                className="form-control"
                name="answer"
                value={answer}
                onChange={this.onChange}
                id="answer"
                required
              />
            </div>
            <div className="form-group d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                Update FAQ{updateLoader}
              </button>
            </div>
          </form>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal id="delete" title="Delete FAQ" style={{ zIndex: 1200 }}>
          {deleteMessage}
          <h3 className="text-center">Are you sure you want to delete?</h3>
          <div className="form-group mt-4 text-center">
            <button className="btn btn-primary m-2" data-bs-dismiss="modal">
              Go back
            </button>
            <button
              className="btn btn-danger"
              onClick={this.deleteFAQ}
              // data-bs-dismiss="modal"
            >
              Continue {deleteLoader}
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}
