import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import PageLoader from "../components/PageLoader";
import Modal from "../components/Modal";
import Loader from "../components/Loader";
import Alert from "../components/Alert";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [loaders, setLoaders] = useState({
    isLoading: true,
    addBalance: false,
    subBalance: false,
  });
  const [messages, setMessages] = useState({
    addBalance: "",
    subBalance: "",
  });
  const [pager, setPager] = useState({
    total: 0,
    page: 1,
    limit: 10,
    next: null,
    previous: null,
  });

  useEffect(() => {
    readUsers();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setMessages({
        addBalance: "",
        subBalance: "",
      });
    }, 3000);
  }, [messages]);

  const readUsers = async (page = 1, search = null, sortBy = "createdAt") => {
    setLoaders({
      ...loaders,
      isLoading: true,
    });

    const params = {
      page: page || 1,
      search: search || "",
      sortBy: sortBy || "createdAt",
    };
    await axios
      .post(server + "/v1/admin/readUsers", params, config)
      .then((rsp) => {
        setUsers(rsp.data.payload.records);
        setPager(rsp.data.payload.pager);
        setLoaders({
          ...loaders,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setUserStatus = async (id, status) => {
    setLoaders({
      ...loaders,
      isLoading: true,
    });

    const params = {
      id: id,
    };
    await axios
      .post(
        server + "/v1/admin/" + (status ? "deactivateUser" : "activateUser"),
        params,
        config
      )
      .then((rsp) => {
        setMessages(rsp.data.payload.messages);
        setLoaders({
          ...loaders,
          isLoading: false,
        });
        readUsers();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addBalance = async (e) => {
    e.preventDefault();
    setLoaders({
      ...loaders,
      addBalance: true,
    });

    const params = {
      id: user._id,
      balance: e.target.balance.value,
    };
    await axios
      .post(server + "/v1/admin/addBalance", params, config)
      .then((rsp) => {
        setMessages({
          ...messages,
          addBalance: <Alert className="success" message={rsp.data.message} />,
        });
        readUsers();
      })
      .catch((err) => {
        setMessages({
          ...messages,
          addBalance: (
            <Alert className="danger" message={err.response?.data?.message} />
          ),
        });
        console.log(err);
      });
    setLoaders({
      ...loaders,
      addBalance: false,
    });
  };

  const subBalance = async (e) => {
    e.preventDefault();
    setLoaders({
      ...loaders,
      subBalance: true,
    });

    const params = {
      id: user._id,
      balance: e.target.balance.value,
    };
    await axios
      .post(server + "/v1/admin/deductBalance", params, config)
      .then((rsp) => {
        setMessages({
          ...messages,
          subBalance: <Alert className="success" message={rsp.data.message} />,
        });
        readUsers();
      })
      .catch((err) => {
        setMessages({
          ...messages,
          subBalance: (
            <Alert className="danger" message={err.response?.data?.message} />
          ),
        });
        console.log(err);
      });
    setLoaders({
      ...loaders,
      subBalance: false,
    });
  };

  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <Header title="Users" />

      <Fragment>
        <div className="card">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center w-100">
              <h5 className="card-title">Users</h5>
              <div className="btn-group">
                <select
                  className="form-control d-none me-2"
                  defaultValue="createdAt"
                  onChange={(e) => readUsers(1, null, e.target.value)}
                >
                  <option value="createdAt">Created At</option>
                  <option value="updatedAt">Updated At</option>
                  <option value="_id">Order ID</option>
                  <option value="price">Price</option>
                </select>
                <input
                  type="text"
                  className="form-control"
                  style={{
                    maxWidth: "200px",
                  }}
                  placeholder="Search..."
                  onChange={(e) => readUsers(1, e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="card-body px-0 pb-0">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>User ID</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Balance</th>
                    <th>Status</th>
                    <th>Last Login</th>
                    <th>Joined At</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {users.map((user) => (
                    <tr key={user._id}>
                      <td>{user._id}</td>
                      <td>{user.username}</td>
                      <td>{user.email}</td>
                      <td>{parseFloat(user.balance).toFixed(2)}</td>
                      <td>
                        {user.isActive ? (
                          <span className="badge bg-success">ACTIVE</span>
                        ) : (
                          <span className="badge bg-danger">BLOCKED</span>
                        )}
                      </td>
                      <td>{new Date(user.lastLogin).toDateString()}</td>
                      <td>{new Date(user.createdAt).toDateString()}</td>
                      <td>
                        <button
                          className={`btn btn-${
                            user.isActive ? "danger" : "success"
                          } btn-sm py-1 px-2 me-2`}
                          onClick={() => setUserStatus(user._id, user.isActive)}
                        >
                          {user.isActive ? (
                            <i className="bi-slash-circle-fill" />
                          ) : (
                            <i className="bi-check" />
                          )}
                        </button>
                        <button
                          className="btn btn-success btn-sm py-1 px-2 me-2"
                          onClick={() => setUser(user)}
                          data-bs-toggle="modal"
                          data-bs-target="#addBalance"
                        >
                          <i className="bi-plus-lg" />
                        </button>
                        <button
                          className="btn btn-warning btn-sm py-1 px-2"
                          onClick={() => setUser(user)}
                          data-bs-toggle="modal"
                          data-bs-target="#subBalance"
                        >
                          <i className="bi-dash-lg" />
                        </button>
                      </td>
                    </tr>
                  ))}
                  {loaders.isLoading && (
                    <tr>
                      <td colSpan="8">
                        <div className="text-center">
                          <Loader />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <nav className="mt-3">
          <ul className="pagination justify-content-center">
            {pager.previous && (
              <li
                className="page-item bg-light rounded"
                onClick={() => readUsers(pager.previous, null, pager.sortBy)}
              >
                <a className="page-link">
                  <i className="bi bi-chevron-double-left"></i>
                </a>
              </li>
            )}

            {pager.previous && (
              <li
                className="page-item bg-light rounded"
                onClick={() => readUsers(pager.previous, null, pager.sortBy)}
              >
                <a className="page-link">{pager.page - 1}</a>
              </li>
            )}

            <li className="page-item bg-primary rounded">
              <a className="page-link" href="#">
                {pager.page}
              </a>
            </li>

            {pager.next && (
              <li
                className="page-item bg-light rounded"
                onClick={() => readUsers(pager.next, null, pager.sortBy)}
              >
                <a className="page-link">{pager.page + 1}</a>
              </li>
            )}

            {pager.next && (
              <li
                className="page-item bg-light rounded"
                onClick={() => readUsers(pager.next, null, pager.sortBy)}
              >
                <a className="page-link" href="#">
                  <i className="bi bi-chevron-double-right"></i>
                </a>
              </li>
            )}
          </ul>
        </nav>
      </Fragment>

      {/* Add Balance Modal */}
      <Modal id="addBalance" title="Add Balance">
        <form onSubmit={addBalance}>
          {messages.addBalance}
          <div className="form-group mb-3">
            <label htmlFor="">Balance($)</label>
            <input
              type="number"
              name="balance"
              id="balance"
              min={1}
              step={0.01}
              default={1}
              className="form-control"
              required
            />
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary">
              Submit {loaders.addBalance && <Loader />}
            </button>
          </div>
        </form>
      </Modal>

      {/* Sub Balance Modal */}
      <Modal id="subBalance" title="Subtract Balance">
        <form onSubmit={subBalance}>
          {messages.subBalance}
          <div className="form-group mb-3">
            <label htmlFor="">Balance($)</label>
            <input
              type="number"
              name="balance"
              id="balance"
              min={1}
              step={0.01}
              default={1}
              className="form-control"
              required
            />
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary">
              Submit {loaders.subBalance && <Loader />}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Users;
