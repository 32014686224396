import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import PageLoader from "../components/PageLoader";
import Modal from "../components/Modal";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import Requests from "../utils/requests";
import { Link } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { quillToolbarOptions } from "../utils/helper";

const requests = new Requests();

const Products = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState("");
  const [product, setProduct] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productOptions, setProductOptions] = useState([]);
  const [productOption, setProductOption] = useState([]);
  const [viewMode, setViewMode] = useState("list");
  // const [productCategories, setProductCategories] = useState([]);
  const [updateStockObj, setUpdateStockObj] = useState({});
  const [addStockObj, setAddStockObj] = useState({});
  const [keys, setKeys] = useState("");
  const [productFeedbacks, setProductFeedbacks] = useState({
    feedbacks: [],
    pager: { page: 1 },
  });
  const [subOptions, setSubOptions] = useState([
    {
      title: "",
      price: 0,
      duration: 0,
    },
  ]);

  const [loaders, setLoaders] = useState({
    isLoading: true,
    create: false,
    update: false,
    delete: false,
    arrange: false,
    generate: false,
  });
  const [messages, setMessages] = useState({
    create: "",
    update: "",
  });

  useEffect(() => {
    readProducts();
    readCategories();

    var updateStock = window.ace.edit("updateStock");
    updateStock.setTheme("ace/theme/monokai");
    updateStock.session.setMode("ace/mode/text");
    setUpdateStockObj(updateStock);

    var addStock = window.ace.edit("addStock");
    addStock.setTheme("ace/theme/monokai");
    addStock.session.setMode("ace/mode/text");
    setAddStockObj(addStock);

    var keys = window.ace.edit("keys");
    keys.setTheme("ace/theme/monokai");
    keys.session.setMode("ace/mode/text");
    setKeys(keys);
  }, []);

  // read dashboard
  const readProducts = async () => {
    setLoaders({ ...loaders, isLoading: true });

    await axios
      .get(server + "/v1/product/read")
      .then((rsp) => {
        setProducts(rsp.data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoaders({ ...loaders, isLoading: false });
  };

  // read categories
  const readCategories = async () => {
    await axios
      .get(server + "/v1/category/read")
      .then((rsp) => {
        setCategories(rsp.data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // read product options
  const readProductOptions = async (id) => {
    setLoaders({ ...loaders, isLoading: true });

    await axios
      .get(server + "/v1/product/option/read/" + id, config)
      .then((rsp) => {
        setProductOptions(rsp.data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoaders({ ...loaders, isLoading: false });
  };

  const create = async (e) => {
    e.preventDefault();
    setLoaders({ ...loaders, create: true });

    const form = e.target;
    const data = new FormData(form);
    data.append("categories", form.category.value);
    data.append("description", productDescription);

    await axios
      .post(server + "/v1/product/create", data, config)
      .then((rsp) => {
        readProducts();
        setMessages({
          ...messages,
          create: <Alert className="success" message={rsp.data.message} />,
        });
        setTimeout(() => {
          setMessages({ ...messages, create: "" });
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });

    form.reset();
    setLoaders({ ...loaders, create: false });
    hideModal("create");
  };

  const update = async (e) => {
    e.preventDefault();
    setLoaders({ ...loaders, create: true });

    const form = e.target;
    const data = new FormData(form);
    data.append("description", product.description);
    data.append("id", product._id);

    await axios
      .put(server + "/v1/product/update", data, config)
      .then((rsp) => {
        readProducts();
        setProduct({});
        setMessages({
          ...messages,
          update: <Alert className="success" message={rsp.data.message} />,
        });
        setTimeout(() => {
          setMessages({ ...messages, update: "" });
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });

    form.reset();
    setLoaders({ ...loaders, update: false });
    hideModal("update");
  };

  const deleteProduct = async (e) => {
    setLoaders({ ...loaders, delete: false });

    await axios
      .delete(server + "/v1/product/delete/" + product._id, config)
      .then((rsp) => {
        readProducts();
      })
      .catch((err) => {
        console.log(err);
      });
    setLoaders({ ...loaders, delete: false });
    hideModal("deleteProduct");
  };

  const createOption = async (e) => {
    e.preventDefault();
    setLoaders({ ...loaders, create: true });

    const form = e.target;
    // remove empty string from stock
    var stock = addStockObj.getValue().split("\n");
    stock = stock.filter((s) => s !== "");
    const data = {
      productId: product._id,
      title: form.title.value,
      stock,
      isReplacement: form.isReplacement.value,
      isAlwaysPending: form.isAlwaysPending.value,
      subOptions: subOptions,
    };

    await axios
      .post(server + "/v1/product/option/create", data, config)
      .then((rsp) => {
        readProductOptions(product._id);
      })
      .catch((err) => {
        console.log(err);
      });

    form.reset();
    setLoaders({ ...loaders, create: false });
    hideModal("createOption");
  };

  const updateOption = async (e) => {
    e.preventDefault();
    setLoaders({ ...loaders, update: true });

    const form = e.target;
    const data = productOption;

    // remove empty string from stock
    var stock = updateStockObj.getValue().split("\n");
    stock = stock.filter((s) => s !== "");

    data.id = data._id;
    data.stock = stock;
    data.subOptions = subOptions;

    await axios
      .put(server + "/v1/product/option/update", data, config)
      .then((rsp) => {
        readProductOptions(product._id);
      })
      .catch((err) => {
        console.log(err);
      });

    form.reset();
    setLoaders({ ...loaders, update: false });
    hideModal("updateOption");
  };

  const deleteOption = async () => {
    setLoaders({ ...loaders, delete: false });

    await axios
      .delete(server + "/v1/product/option/delete/" + productOption._id, config)
      .then((rsp) => {
        readProductOptions(product._id);
      })
      .catch((err) => {
        console.log(err);
      });

    setLoaders({ ...loaders, delete: false });
    hideModal("deleteOption");
  };

  const hideModal = (id) => {
    const dom = document.getElementById("close" + id);
    if (dom) dom.click();
  };

  const readFeedbacks = async (page = 1, id) => {
    const { feedbacks, pager } = await requests.readProductFeedbacks(page, id);
    setProductFeedbacks({
      feedbacks,
      pager,
    });
  };

  const deleteFeedback = async (id) => {
    await requests.deleteFeedback(id);
    readFeedbacks(productFeedbacks.pager.currentPage, product._id);
  };

  const arrangeProducts = async () => {
    setLoaders({ ...loaders, arrange: true });
    const pds = [];
    for (let i = 0; i < products.length; i++) {
      pds.push({ id: products[i]._id });
    }
    await requests.arrangeProducts(pds);
    setLoaders({ ...loaders, arrange: false });
    hideModal("arrangeProducts");
  };

  const arrangeProductOptions = async () => {
    setLoaders({ ...loaders, arrange: true });
    const pds = [];
    for (let i = 0; i < productOptions.length; i++) {
      pds.push({ id: productOptions[i]._id });
    }
    await requests.arrangeProductOptions(pds);
    setLoaders({ ...loaders, arrange: false });
    hideModal("arrangeOptions");
  };

  const generateKeys = async (e) => {
    e.preventDefault();
    setLoaders({ ...loaders, generate: true });
    const form = e.target;
    await requests.generateKeys(
      productOption._id,
      form.amount.value,
      form.duration.value,
      keys.getValue().split("\n")
    );
    setLoaders({ ...loaders, generate: false });
    readProductOptions(product._id);
    hideModal("generateKeys");
  };

  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <Header title="Products" />

      <div className="d-flex justify-content-end align-items-center w-100 mb-3">
        <button
          className="btn btn-primary btn-sm me-2"
          data-bs-toggle="modal"
          data-bs-target="#create"
        >
          <i className="bi-plus"></i> Add Product
        </button>
        <button
          className="btn btn-warning btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#arrangeProducts"
        >
          <i className="bi-arrows-move"></i>
        </button>
      </div>

      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center w-100">
            <h5 className="card-title">Products</h5>
            <div className="btn-group">
              <input
                type="text"
                className="form-control"
                style={{
                  maxWidth: "200px",
                }}
                placeholder="Search..."
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          {viewMode === "product" && (
            <button
              className="btn btn-sm btn-warning me-2"
              onClick={() => setViewMode("list")}
            >
              <i className="bi-arrow-left"></i> Go Back
            </button>
          )}
        </div>

        {viewMode === "list" && (
          <div className="card-body px-0 pb-0">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Product ID</th>
                    <th>Product Name</th>
                    <th>Product Category</th>
                    <th>Product Label</th>
                    <th>FullFill Mode</th>
                    <th>Action</th>
                  </tr>
                </thead>

                {!loaders.isLoading ? (
                  <tbody>
                    {products
                      .filter(
                        (c) =>
                          c.title
                            .toLowerCase()
                            ?.includes(search.toLocaleLowerCase()) ||
                          c.categories[0]?.name
                            ?.toLowerCase()
                            ?.includes(search.toLocaleLowerCase())
                      )
                      .map((product) => (
                        <tr key={product._id}>
                          <td>{product._id}</td>
                          <td>{product.title}</td>
                          <td>
                            {product.categories.map((category) => (
                              <span
                                key={category._id}
                                className="badge text-black me-1"
                                style={{
                                  background: category.color,
                                }}
                              >
                                {category.name}
                              </span>
                            ))}
                          </td>
                          <td>{product.label}</td>
                          <td>{product.fullFillmode.toUpperCase()}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-primary me-1 px-2 py-1"
                              onClick={() => {
                                setProduct(product);
                                setViewMode("product");
                                readProductOptions(product._id);
                              }}
                            >
                              <i className="bi-eye"></i>
                            </button>
                            <button
                              className="btn btn-sm btn-warning me-1 px-2 py-1"
                              onClick={() => {
                                setProduct(product);
                                // setProductCategories(product.categories);
                                readProductOptions(product._id);
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#update"
                            >
                              <i className="bi-pen"></i>
                            </button>
                            <button
                              className="btn btn-sm btn-primary me-1 px-2 py-1"
                              onClick={() => {
                                setProduct(product);
                                readFeedbacks(1, product._id);
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#feedbacks"
                            >
                              <i className="bi-star-fill"></i>
                            </button>
                            <button
                              className="btn btn-sm btn-danger px-2 py-1"
                              onClick={async () => {
                                setProduct({ ...product });
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#delete"
                            >
                              <i className="bi-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="6">
                        <PageLoader />
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        )}

        {/* Product Settings */}
        {viewMode === "product" && (
          <div className="card-body px-0 pb-0">
            <div className="row">
              <div className="col-md-6">
                <div className="text-center">
                  <h5>Product Details</h5>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <th>ID</th>
                        <td>{product._id}</td>
                      </tr>

                      <tr>
                        <th>Title</th>
                        <td>{product.title}</td>
                      </tr>
                      <tr>
                        <th>Label</th>
                        <td>{product.label}</td>
                      </tr>
                      <tr>
                        <th>Categories</th>
                        <td>
                          {product.categories.map((category) => (
                            <span
                              key={category._id}
                              className="badge text-black me-1"
                              style={{
                                background: category.color,
                              }}
                            >
                              {category.name}
                            </span>
                          ))}
                        </td>
                      </tr>
                      <tr>
                        <th>Picture</th>
                        <td>
                          <img
                            src={server + product.picture}
                            alt=""
                            style={{
                              maxHeight: "100px",
                              maxWidth: "100px",
                            }}
                            className="rounded"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>FullFill Mode</th>
                        <td>{product.fullFillmode.toUpperCase()}</td>
                      </tr>
                      <tr>
                        <th>Minimum Quantity</th>
                        <td>{product.minQuantity}</td>
                      </tr>
                      <tr>
                        <th>Maximum Quantity</th>
                        <td>{product.maxQuantity}</td>
                      </tr>
                      <tr>
                        <th>Discount Available</th>
                        <td>{product.disableDiscount ? "No" : "Yes"}</td>
                      </tr>
                      <tr>
                        <th>Total Stock</th>
                        <td>{product.totalStock}</td>
                      </tr>
                      <tr>
                        <th>Description</th>
                        <td>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: product.description,
                            }}
                          ></p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-center">
                  <h5>Product Options</h5>
                </div>
                <div className="text-end mb-3 me-3">
                  <button
                    className="btn btn-primary btn-sm px-2 me-1"
                    data-bs-toggle="modal"
                    data-bs-target="#createOption"
                    onClick={() => {
                      setSubOptions([]);
                    }}
                  >
                    <i className="bi-plus"></i> Add Option
                  </button>
                  <button
                    className="btn btn-sm btn-warning"
                    data-bs-toggle="modal"
                    data-bs-target="#arrangeOptions"
                  >
                    <i className="bi-arrows-move"></i>
                  </button>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Rep.</th>
                        <th>Aws. Pen.</th>
                        <th>Stock</th>
                        <th>Keys</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productOptions.map((po) => (
                        <tr>
                          <th>{po.title}</th>
                          <td>{po.isReplacement ? "Yes" : "No"}</td>
                          <td>{po.isAlwaysPending ? "Yes" : "No"}</td>
                          <td>{po.stock?.length || 0}</td>
                          <td>{po.keys?.length || 0}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-warning me-1 px-2 py-1"
                              onClick={async () => {
                                setProductOption(po);
                                setSubOptions(po.subOptions || []);
                                await updateStockObj.setValue(
                                  po.stock?.join("\n")
                                );
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#updateOption"
                            >
                              <i className="bi-pen"></i>
                            </button>
                            <button
                              className="btn btn-sm btn-primary me-1 px-2 py-1"
                              onClick={async () => {
                                setProductOption(po);
                                await keys.setValue(po.keys?.join("\n"));
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#generateKeys"
                            >
                              <i className="bi-key"></i>
                            </button>
                            <button
                              className="btn btn-sm btn-danger px-2 py-1"
                              onClick={() => {
                                setProductOption(po);
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#deleteOption"
                            >
                              <i className="bi-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Create */}
      <Modal id="create" title="Create Product" className="modal-lg">
        <form onSubmit={create}>
          {messages.create}
          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-3">
                <label htmlFor="name">Title</label>
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-3">
                <label htmlFor="name">Label</label>
                <input
                  type="text"
                  name="label"
                  className="form-control"
                  required
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group mb-3">
                <label htmlFor="name">Category</label>
                <select
                  name="category"
                  id="category"
                  className="form-control"
                  required
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group mb-3">
                <label htmlFor="name">Picture</label>
                <input
                  type="file"
                  name="picture"
                  className="form-control"
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-3">
                <label htmlFor="name">Fullfill Mode </label>
                <select
                  name="fullFillmode"
                  id="fullFillmode"
                  className="form-control"
                >
                  <option value="auto">Automatically</option>
                  <option value="manual">Manully</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-3">
                <label htmlFor="name">Min Quantity</label>
                <input
                  type="number"
                  name="minQuantity"
                  className="form-control"
                  min={1}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-3">
                <label htmlFor="name">Max Quantity</label>
                <input
                  type="number"
                  name="maxQuantity"
                  className="form-control"
                  min={1}
                  required
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group mb-3">
                <label htmlFor="name">Disable Discount</label>
                <select
                  name="disableDiscount"
                  id="disableDiscount"
                  className="form-control"
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group mb-3">
                <label htmlFor="name">Description</label>
                <ReactQuill
                  theme="snow"
                  modules={{ toolbar: quillToolbarOptions }}
                  value={productDescription}
                  onChange={(value) => setProductDescription(value)}
                  style={{
                    height: "20vh",
                    marginBottom: "15vh",
                    position: "relative",
                  }}
                  key="create"
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <button type="submit" className="btn btn-primary">
              Submit {loaders.create && <Loader />}
            </button>
          </div>
        </form>
      </Modal>

      {/* Update */}
      <Modal id="update" title="Update Product" className="modal-lg">
        {product && (
          <form onSubmit={update}>
            {messages.update}
            <input type="hidden" name="id" value={product._id} />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="name">Title</label>
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    value={product.title}
                    onChange={(e) =>
                      setProduct({ ...product, title: e.target.value })
                    }
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="name">Label</label>
                  <input
                    type="text"
                    name="label"
                    className="form-control"
                    value={product.label}
                    onChange={(e) =>
                      setProduct({ ...product, label: e.target.value })
                    }
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="name">Category</label>
                  <select
                    name="categories"
                    id="category"
                    className="form-control"
                    required
                    value={
                      product.categories?.length > 0
                        ? product.categories[0]._id
                        : ""
                    }
                    onChange={(e) =>
                      setProduct({ ...product, categories: e.target.value })
                    }
                  >
                    <option value="">Select Categories</option>
                    {categories.map((category) => (
                      <option key={category._id} value={category._id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="name">Picture(leave blank for old)</label>
                  <input type="file" name="picture" className="form-control" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="name">Fullfill Mode </label>
                  <select
                    name="fullFillmode"
                    id="fullFillmode"
                    className="form-control"
                    value={product.fullFillmode}
                    onChange={(e) =>
                      setProduct({ ...product, fullFillmode: e.target.value })
                    }
                  >
                    <option value="auto">Automatically</option>
                    <option value="manual">Manully</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="name">Min Quantity</label>
                  <input
                    type="number"
                    name="minQuantity"
                    className="form-control"
                    min={1}
                    value={product.minQuantity}
                    onChange={(e) =>
                      setProduct({ ...product, minQuantity: e.target.value })
                    }
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="name">Max Quantity</label>
                  <input
                    type="number"
                    name="maxQuantity"
                    className="form-control"
                    min={1}
                    value={product.maxQuantity}
                    onChange={(e) =>
                      setProduct({ ...product, maxQuantity: e.target.value })
                    }
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="name">Disable Discount</label>
                  <select
                    name="disableDiscount"
                    id="disableDiscount"
                    className="form-control"
                    value={product.disableDiscount}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        disableDiscount: e.target.value,
                      })
                    }
                  >
                    <option value={false}>No</option>
                    <option value={true}>Yes</option>
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label htmlFor="name">Description</label>
                  <ReactQuill
                    theme="snow"
                    modules={{ toolbar: quillToolbarOptions }}
                    value={product.description}
                    onChange={(value) =>
                      setProduct({ ...product, description: value })
                    }
                    style={{
                      height: "20vh",
                      marginBottom: "15vh",
                      position: "relative",
                      color: "black",
                    }}
                    key="update"
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-primary">
                Submit {loaders.update && <Loader />}
              </button>
            </div>
          </form>
        )}
      </Modal>

      {/* Delete */}
      <Modal id="delete" title="Delete Product">
        <div className="text-center">
          <h3>Are you sure you want to delete?</h3>
          <div className="form-group mt-4">
            <button className="btn btn-primary m-2" data-bs-dismiss="modal">
              Go back
            </button>
            <button
              className="btn btn-danger"
              onClick={deleteProduct}
              data-bs-dismiss="modal"
            >
              Continue {loaders.delete && <Loader />}
            </button>
          </div>
        </div>
      </Modal>

      {/* Create Option */}
      <Modal id="createOption" title="Create Product Option">
        <form onSubmit={createOption}>
          <div className="form-group mb-3">
            <label htmlFor="name">Title</label>
            <input
              type="text"
              name="title"
              className="form-control"
              autoFocus
              required
            />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="name">Is Replacement Available</label>
            <select
              name="isReplacement"
              id="isReplacement"
              className="form-control"
            >
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </select>
          </div>

          <div className="form-group mb-3">
            <label htmlFor="name">Is Always Pending</label>
            <select
              name="isAlwaysPending"
              id="isAlwaysPending"
              className="form-control"
            >
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </select>
          </div>

          <div className="form-group mb-3">
            <label htmlFor="name" className="mb-2">
              Sub Options
            </label>
            {subOptions.map((subOption, index) => (
              <div className="row mb-2" key={index}>
                <div className="col-3">
                  <label htmlFor="">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    value={subOption.title}
                    required
                    onChange={(e) =>
                      setSubOptions(
                        subOptions.map((subOption, i) =>
                          i === index
                            ? { ...subOption, title: e.target.value }
                            : subOption
                        )
                      )
                    }
                  />
                </div>
                <div className="col-3">
                  <label htmlFor="">Price($)</label>
                  <input
                    type="number"
                    className="form-control"
                    value={subOption.price}
                    required
                    onChange={(e) =>
                      setSubOptions(
                        subOptions.map((subOption, i) =>
                          i === index
                            ? {
                                ...subOption,
                                price: parseFloat(e.target.value),
                              }
                            : subOption
                        )
                      )
                    }
                  />
                </div>
                <div className="col-3">
                  <label htmlFor="">Duration(Days)</label>
                  <input
                    type="number"
                    className="form-control"
                    value={subOption.duration}
                    required
                    onChange={(e) =>
                      setSubOptions(
                        subOptions.map((subOption, i) =>
                          i === index
                            ? {
                                ...subOption,
                                duration: parseInt(e.target.value),
                              }
                            : subOption
                        )
                      )
                    }
                  />
                </div>
                <div className="col-3 pt-1">
                  <button
                    className="btn btn-danger mt-3"
                    type="button"
                    onClick={() => {
                      setSubOptions(
                        subOptions.filter((subOption, i) => i !== index)
                      );
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
            <div>
              <button
                className="btn btn-primary btn-sm mt-3"
                type="button"
                onClick={() => {
                  setSubOptions([
                    ...subOptions,
                    { title: "", price: 0, duration: 0 },
                  ]);
                }}
              >
                + Add
              </button>
            </div>
          </div>

          <div className="form-group mb-3">
            <label htmlFor="name">Stock (Lines)</label>
            <div
              name="stock"
              id="addStock"
              style={{
                height: "300px",
              }}
              className="form-control"
            ></div>
          </div>
          <div className="form-group mb-3">
            <button type="submit" className="btn btn-primary">
              Submit {loaders.create && <Loader />}
            </button>
          </div>
        </form>
      </Modal>

      {/* Update Option */}
      <Modal id="updateOption" title="Update Product Option">
        <form onSubmit={updateOption}>
          <div className="form-group mb-3">
            <label htmlFor="name">Title</label>
            <input
              type="text"
              name="title"
              className="form-control"
              value={productOption.title}
              onChange={(e) =>
                setProductOption({ ...productOption, title: e.target.value })
              }
              required
            />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="name">Is Replacement Available</label>
            <select
              name="isReplacement"
              id="isReplacement"
              className="form-control"
              value={productOption.isReplacement}
              onChange={(e) =>
                setProductOption({
                  ...productOption,
                  isReplacement: e.target.value,
                })
              }
            >
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </select>
          </div>

          <div className="form-group mb-3">
            <label htmlFor="name">Is Always Pending</label>
            <select
              name="isAlwaysPending"
              id="isAlwaysPending"
              className="form-control"
              value={productOption.isAlwaysPending}
              onChange={(e) =>
                setProductOption({
                  ...productOption,
                  isAlwaysPending: e.target.value,
                })
              }
            >
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </select>
          </div>

          <div className="form-group mb-3">
            <label htmlFor="name" className="mb-2">
              Sub Options
            </label>
            {subOptions.map((subOption, index) => (
              <div className="row mb-2" key={index}>
                <div className="col-3">
                  <label htmlFor="">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    value={subOption.title}
                    required
                    onChange={(e) =>
                      setSubOptions(
                        subOptions.map((subOption, i) =>
                          i === index
                            ? { ...subOption, title: e.target.value }
                            : subOption
                        )
                      )
                    }
                  />
                </div>
                <div className="col-3">
                  <label htmlFor="">Price($)</label>
                  <input
                    type="number"
                    className="form-control"
                    value={subOption.price}
                    required
                    onChange={(e) =>
                      setSubOptions(
                        subOptions.map((subOption, i) =>
                          i === index
                            ? {
                                ...subOption,
                                price: parseFloat(e.target.value),
                              }
                            : subOption
                        )
                      )
                    }
                  />
                </div>
                <div className="col-3">
                  <label htmlFor="">Duration(Days)</label>
                  <input
                    type="number"
                    className="form-control"
                    value={subOption.duration}
                    required
                    onChange={(e) =>
                      setSubOptions(
                        subOptions.map((subOption, i) =>
                          i === index
                            ? {
                                ...subOption,
                                duration: parseInt(e.target.value),
                              }
                            : subOption
                        )
                      )
                    }
                  />
                </div>
                <div className="col-3 pt-1">
                  <button
                    className="btn btn-danger mt-3"
                    type="button"
                    onClick={() => {
                      setSubOptions(
                        subOptions.filter((subOption, i) => i !== index)
                      );
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
            <div>
              <button
                className="btn btn-primary btn-sm mt-3"
                type="button"
                onClick={() => {
                  setSubOptions([
                    ...subOptions,
                    { title: "", price: 0, duration: 0 },
                  ]);
                }}
              >
                + Add
              </button>
            </div>
          </div>

          <div className="form-group mb-3">
            <label htmlFor="name">Stock (Lines)</label>
            <div
              name="stock"
              id="updateStock"
              style={{
                height: "300px",
              }}
              className="form-control"
            ></div>
          </div>

          <div className="form-group mb-3">
            <button type="submit" className="btn btn-primary">
              Submit {loaders.update && <Loader />}
            </button>
          </div>
        </form>
      </Modal>

      {/* Delete Option */}
      <Modal id="deleteOption" title="Delete Product Option">
        <div className="text-center">
          <h3>Are you sure you want to delete?</h3>
          <div className="form-group mt-4">
            <button className="btn btn-primary m-2" data-bs-dismiss="modal">
              Go back
            </button>
            <button
              className="btn btn-danger"
              onClick={deleteOption}
              data-bs-dismiss="modal"
            >
              Continue {loaders.delete && <Loader />}
            </button>
          </div>
        </div>
      </Modal>

      {/* View Feedbacks */}
      <Modal id="feedbacks" title="Product Feedbacks" className="modal-lg">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Message</th>
                <th>Rating</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {productFeedbacks.feedbacks.map((feedback, index) => (
                <tr key={index}>
                  <td>
                    {(productFeedbacks.pager.page - 1) *
                      productFeedbacks.pager.limit +
                      (index + 1)}
                  </td>
                  <td>{feedback.message}</td>
                  <td>{feedback.stars}</td>
                  <td>{new Date(feedback.createdAt).toDateString()}</td>
                  <td>
                    <button
                      className="btn btn-danger btn-sm px-2 py-1"
                      onClick={() => deleteFeedback(feedback._id)}
                    >
                      <i className="bi-trash"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <nav className="mt-3">
          <ul className="pagination justify-content-center">
            {productFeedbacks.pager.previous && (
              <li
                className="page-item bg-light rounded"
                onClick={() =>
                  readFeedbacks(
                    productFeedbacks.pager.previous,
                    null,
                    productFeedbacks.pager.sortBy
                  )
                }
              >
                <Link to="#" className="page-link">
                  <i className="bi bi-chevron-double-left"></i>
                </Link>
              </li>
            )}

            {productFeedbacks.pager.previous && (
              <li
                className="page-item bg-light rounded"
                onClick={() =>
                  readFeedbacks(
                    productFeedbacks.pager.previous,
                    null,
                    productFeedbacks.pager.sortBy
                  )
                }
              >
                <Link to="#" className="page-link">
                  {productFeedbacks.pager.page - 1}
                </Link>
              </li>
            )}

            <li className="page-item bg-primary rounded">
              <Link to="#" className="page-link" href="#">
                {productFeedbacks.pager.page}
              </Link>
            </li>

            {productFeedbacks.pager.next && (
              <li
                className="page-item bg-light rounded"
                onClick={() =>
                  readFeedbacks(
                    productFeedbacks.pager.next,
                    null,
                    productFeedbacks.pager.sortBy
                  )
                }
              >
                <Link to="#" className="page-link">
                  {productFeedbacks.pager.page + 1}
                </Link>
              </li>
            )}

            {productFeedbacks.pager.next && (
              <li
                className="page-item bg-light rounded"
                onClick={() =>
                  readFeedbacks(
                    productFeedbacks.pager.next,
                    null,
                    productFeedbacks.pager.sortBy
                  )
                }
              >
                <Link to="#" className="page-link" href="#">
                  <i className="bi bi-chevron-double-right"></i>
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </Modal>

      {/* arrange products */}
      <Modal id="arrangeProducts" title="Arrange Products">
        <ReactSortable
          key={"products"}
          list={products}
          setList={(products) => {
            setProducts(products);
          }}
          animation={200}
          group={{ name: "products" }}
          style={{
            overflowY: "auto",
          }}
        >
          {products.map((product, index) => (
            <div key={index} className="card mb-3">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>{product.title}</h5>
                  {product.categories.map((category) => (
                    <span
                      key={category._id}
                      className="badge text-black me-1"
                      style={{
                        background: category.color,
                      }}
                    >
                      {category.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </ReactSortable>
        <div className="text-end">
          <button className="btn btn-primary" onClick={arrangeProducts}>
            Save {loaders.arrange && <Loader />}
          </button>
        </div>
      </Modal>

      {/* arrange product options */}
      <Modal id="arrangeOptions" title="Arrange Product Options">
        <ReactSortable
          key={"productOptions"}
          list={productOptions}
          setList={(productOptions) => {
            setProductOptions(productOptions);
          }}
          animation={200}
          group={{ name: "productOptions" }}
          style={{
            overflowY: "auto",
          }}
        >
          {productOptions.map((product, index) => (
            <div key={index} className="card mb-3">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>{product.title}</h5>

                  <div>
                    <span className="badge bg-success">${product.price}</span>
                    {" - "}
                    <span className="badge bg-info">
                      {product.duration} Days
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </ReactSortable>
        <div className="text-end">
          <button className="btn btn-primary" onClick={arrangeProductOptions}>
            Save {loaders.arrange && <Loader />}
          </button>
        </div>
      </Modal>

      {/* generate keys */}
      <Modal id="generateKeys" title="Generate Keys">
        <form onSubmit={generateKeys}>
          <div className="form-group mb-3">
            <label htmlFor="keys">Active Keys</label>
            <div
              name="keys"
              id="keys"
              style={{
                height: "300px",
              }}
              className="form-control"
            ></div>
          </div>
          <hr />
          <div className="form-group mb-3">
            <label htmlFor="quantity">Quantity</label>
            <input
              type="number"
              name="amount"
              className="form-control"
              id="quantity"
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="quantity">Duration (Same as sub option)</label>
            <input
              type="number"
              name="duration"
              className="form-control"
              id="duration"
              required
            />
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary">
              Generate {loaders.generate && <Loader />}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Products;
